import React, { useEffect, useState } from "react";
import CommonLayout from "../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../services/apiServices";
import { getAuthConfig } from "../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import { InputGroup, Form, CardFooter, Modal, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Row, Col } from "react-bootstrap";
import "./TopSong.css";

const TopSong = () => {
    const navigate = useNavigate();
    const [topSongs, setTopSongs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [deletingId, setDeletingId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const fetchTopSongList = async (page = 1) => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.getTopSong(
                { page, limit: 10 },
                getAuthConfig()
            );
            const { success, data } = response;

            if (success && Array.isArray(data.topSongsList)) {
                setTopSongs(data.topSongsList);
            } else {
                console.warn("No data found or data is not an array");
            }
        } catch (error) {
            console.error("Error fetching top songs:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSearchSong = async (song_name) => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.getSearchSong(
                { song_name },
                getAuthConfig()
            );
            const searchList = response.data || [];
            if (searchList.length > 0) {
                setSearchResults(searchList);
            } else {
                setSearchResults([]);
                toast.info("No results found");
            }
        } catch (error) {
            toast.error("Failed to fetch search song");
        } finally {
            setLoading(false);
        }
    };

    const addTopSong = async (song_id) => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.addTopSong(
                { song_id },
                getAuthConfig()
            );
            if (response && response.success) {
                setSearchTerm("");
            } else {
                toast.error("Failed to add song.");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOnDelete = async () => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.deleteTopSong(
                { id: deletingId },
                getAuthConfig()
            );

            if (response && response.status === 200) {
                setShowDeleteModal(false);
                setTopSongs((prevSongs) =>
                    prevSongs.filter((song) => song._id !== deletingId)
                );
            } else {
                toast.error("Failed to delete song!");
            }
        } catch (error) {
            toast.error("Failed to delete song!");
        } finally {
            setLoading(false);
            setDeletingId(null);
        }
    };

    const confirmDelete = (songId) => {
        setDeletingId(songId);
        setShowDeleteModal(true);
    };

    const handleSearchTermChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.trim()) {
            fetchSearchSong(value);
        } else {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        if (!searchTerm) {
            fetchTopSongList(currentPage);
        }
    }, [currentPage, searchTerm]);

    return (
        <CommonLayout>
            <Container className="mt-5 pt-3">

                <div className="container">
                    <h3 style={{
                        textAlign: "center",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "2.8rem",
                        fontWeight: "600",
                        letterSpacing: "1.5px",
                        textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                        marginBottom: "10px",
                        background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
                        WebkitBackgroundClip: "text",
                    }}>TOP SONGS</h3>

                    <InputGroup className="mb-3">
                        <Form.Control
                            className="search-input"
                            placeholder="Search for a song..."
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                        />
                    </InputGroup>

                    {loading ? (
                        <div className="text-center">Loading...</div>
                    ) : searchTerm && searchResults.length > 0 ? (
                        <div className="song-card-container mb-5">
                            {searchResults.map((song) => (
                                <div key={song._id} className="card">
                                    <img
                                        style={{
                                            width: "100%",
                                            maxHeight: "200px",
                                        }}
                                        src={song?.artist?.artist_image_link || "/default-image.jpg"}
                                        alt={song.song_name || "Unknown Song"}
                                    />
                                    <h3 className="text-center"><ReadMoreText text={song.song_name} maxLength={20} minimumLenth={9} isTitle /></h3>
                                    <h4 className="text-center">Artist: {song.artist?.artist_name || "Unknown Artist"}</h4>
                                    <p className="text-center">Album: {song.album?.album_name || "Unknown Album"}</p>
                                    <CardFooter>
                                        <Button
                                            className="add-button"
                                            onClick={() => addTopSong(song.song_id)}
                                        >
                                            Add to Top Songs
                                        </Button>
                                    </CardFooter>
                                </div>
                            ))}
                        </div>
                    ) : (
                        // <div className="card-container">
                        //     {topSongs.length > 0 ? (
                        //         topSongs.map((song) => (
                        //             <div key={song._id} className="card">
                        //                 <img
                        //                     src={song?.artist?.artist_image_link || "/default-image.jpg"}
                        //                     alt={song.song_name || "Unknown Song"}
                        //                 />
                        //                 <h3>{song.song_name}</h3>
                        //                 <h4>Artist: {song.artist?.artist_name || "Unknown Artist"}</h4>
                        //                 <p>Album: {song.album?.album_name || "Unknown Album"}</p>
                        //                 <CardFooter>
                        //                     <Button
                        //                         className="show-button"
                        //                         onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                        //                     >
                        //                         See Lyrics
                        //                     </Button>
                        //                     <Button
                        //                         className="delete-button"
                        //                         onClick={() => confirmDelete(song._id)}
                        //                     >
                        //                         Delete
                        //                     </Button>
                        //                 </CardFooter>
                        //             </div>
                        //         ))
                        //     ) : (
                        //         <p>No songs available.</p>
                        //     )}
                        // </div>
                        <div className="song-card-container">
                            {topSongs.length > 0 ? (
                                topSongs.map((song) => (
                                    <div key={song._id} className="song-card">
                                        <img
                                            src={song?.artist?.artist_image_link || "/default-image.jpg"}
                                            alt={song.song_name || "Unknown Song"}
                                        />
                                        <h3>{song.song_name}</h3>
                                        <h4>Artist: {song.artist?.artist_name || "Unknown Artist"}</h4>
                                        <p>Album: {song.album?.album_name || "Unknown Album"}</p>
                                        <CardFooter className="mx-2 my-1">
                                            <Button
                                                className="view-lyrics-button"
                                                onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                                            >
                                                See Lyrics
                                            </Button>
                                            <Button
                                                className="remove-song-button  "
                                                onClick={() => confirmDelete(song._id)}
                                            >
                                                Delete
                                            </Button>
                                        </CardFooter>
                                    </div>
                                ))
                            ) : (
                                <p>No songs available.</p>
                            )}
                        </div>


                    )}
                    <div className="pagination">
                        {currentPage > 1 && (
                            <button onClick={() => setCurrentPage(currentPage - 1)}>
                                Previous
                            </button>
                        )}
                        {currentPage < totalPages && (
                            <button onClick={() => setCurrentPage(currentPage + 1)}>
                                Next
                            </button>
                        )}
                    </div>
                </div>

                {/* Delete Confirmation Modal */}
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this song?</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleOnDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </CommonLayout>
    );
};

const ReadMoreText = ({ text, maxLength = 15, isTitle = false, minimumLenth = 11 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const truncatedText =
        text.length > maxLength && !isExpanded
            ? `${text.slice(minimumLenth, maxLength)}...`
            : text;

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <span>
            {truncatedText}
            {text.length > maxLength && (
                <span
                    style={{
                        color: "blue",
                        cursor: "pointer",
                        marginLeft: "5px",
                        fontSize: isTitle ? "0.85rem" : "0.8rem",
                    }}
                    onClick={toggleExpanded}
                >
                    {isExpanded ? "read less" : "Read more"}
                </span>
            )}
        </span>
    );
};

export default TopSong;
