import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonLayout from "../../../layouts/CommonLayout";
import axios from "axios";
import { ROUTE_CONSTANT } from "../../../routes/constant";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";

function HomePage() {
  const [trendingCount, setTrendingCount] = useState(0);
  const [recommendedCount, setRecommendedCount] = useState(0);
  const [topExploreCount, setTopExploreCount] = useState(0);

  const navigate = useNavigate();

  const fetchTrendingArtists = async () => {
    try {
      let response = await allAPiServicesCall.getTrendingArtists(
        { limit: 20, page: 1 },
        getAuthConfig()
      );

      const { totalPages, trendingList } = response.data;

      if (totalPages) {
        setTrendingCount(totalPages);
      } else {
        console.warn("No data found or data is not an array");
      }
    } catch (error) {
      console.error("Error fetching trending artists", error);
    }
  };

  const fetchRecommendedArtists = async () => {
    try {
      let response = await allAPiServicesCall.getRecommendedArtists(
        {},
        getAuthConfig()
      );

      const { recommendedList } = response.data;

      if (recommendedList) {
        setRecommendedCount(recommendedList.length);
      } else {
        console.warn("No data found or data is not an array");
      }
    } catch (error) {
      console.error("Error fetching recommended artists", error);
    }
  };

  const fetchTopExploreAlbums = async () => {
    try {
      const response = await allAPiServicesCall.getTopExploreAlbums(
        getAuthConfig()
      );

      const { albums } = response.data;

      if (albums) {
        setTopExploreCount(albums.length);
      } else {
        console.warn("No data found or data is not an array");
      }
    } catch (error) {
      console.error("Error fetching top explore artists", error);
    }
  };

  useEffect(() => {
    fetchTrendingArtists();
    fetchRecommendedArtists();
    fetchTopExploreAlbums();
  }, []);

  return (
    <CommonLayout>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4">
            <div
              className="card"
              onClick={() => navigate(ROUTE_CONSTANT.COMMON.TRENDING_ARTIST)}
            >
              <div className="card-body">
                <h5 className="card-title">Trending Artists</h5>
                <p className="card-text">Total: {trendingCount}</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="card"
              onClick={() => navigate(ROUTE_CONSTANT.COMMON.RECOMMENDED_ARTIST)}
            >
              <div className="card-body">
                <h5 className="card-title">Recommended Artists</h5>
                <p className="card-text">Total: {recommendedCount}</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="card"
              onClick={() => navigate(ROUTE_CONSTANT.COMMON.TOP_EXPLORE)}
            >
              <div className="card-body">
                <h5 className="card-title">Top Explore Artists</h5>
                <p className="card-text">Total: {topExploreCount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
}

export default HomePage;
