import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CommonLayout from '../../../layouts/CommonLayout';
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig, getFormDataConfig } from '../../../services/apiUtils';
import imageCompression from 'browser-image-compression';
import JoditEditor from 'jodit-react';

const AddNews = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [coverImagePath, setCoverImagePath] = useState('');
    const [formLoading, setFormLoading] = useState(false); // Loading for form submission
    const [imageLoading, setImageLoading] = useState(false); // Loading for image upload
    const [author, setAuthor] = useState('');
    const [date, setDate] = useState('');
    const [readTime, setReadTime] = useState(''); // State for readTime
    const [instagram, setInstagram] = useState(''); // State for Instagram handle
    const [authorPic, setAuthorPic] = useState(''); // State for Author Picture URL
    const [authorDesignation, setAuthorDesignation] = useState(''); // State for Author Designation
    const [authImagePath, setAuthImagrPath] = useState('')
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const editor = useRef(null);
    const [genres, setGenres] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState('all'); // State for selected genre

    const handleGenreChange = (e) => {
        setSelectedGenre(e.target.value); // Update selected genre
    };

    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const response = await allAPiServicesCall.getGenres(getAuthConfig())
                setGenres(response.data);
            } catch (error) {
                console.error('Error fetching genres:', error);
            }
        };

        fetchGenres();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormLoading(true);

        const formattedDate = new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).split('/').reverse().join('-');

        const formData = {
            title,
            description,
            coverImg: coverImagePath,
            author,
            publishDate: formattedDate,
            readTime,
            instagram,
            authorPic: authImagePath,
            authorDesignation,
            genre: selectedGenre, // Add selected genre to formData

        };

        try {
            await allAPiServicesCall.addNews(formData, getAuthConfig(), navigate);
            setError(null);
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to submit news.');
        } finally {
            setFormLoading(false);
        }
    };

    const handleImageChange = async (e) => {
        let imageFile = e.target.files[0];

        if (imageFile?.size > 5 * 1024 * 1024) { // Check if file is larger than 5MB
            setError('Image size should not exceed 5 MB.');
            return;
        }

        const options = {
            maxSizeMB: 1, // Compress to 1MB
            maxWidthOrHeight: 1024, // Resize image to 1024x1024
            useWebWorker: true,
            fileType: imageFile.type,
        };

        try {
            setImageLoading(true);
            const compressedFile = await imageCompression(imageFile, options);

            const formData = new FormData();
            formData.append('image', compressedFile);

            const response = await allAPiServicesCall.addNewsCoverPic(formData, getFormDataConfig(), navigate);
            setCoverImagePath(response.data.path);
            setError(null);
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to upload image.');
        } finally {
            setImageLoading(false);
        }
    };


    const handleAuthImageChange = async (e) => {
        setImageLoading(true);
        let imageFile = e.target.files[0];

        if (imageFile?.size > 5 * 1024 * 1024) { // Check if file is larger than 5MB
            setError('Image size should not exceed 5 MB.');
            return;
        }

        const options = {
            maxSizeMB: 1, // Compress to 1MB
            maxWidthOrHeight: 1024, // Resize image to 1024x1024
            useWebWorker: true,
            fileType: imageFile.type,
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);

            const formData = new FormData();
            formData.append('image', compressedFile);

            const response = await allAPiServicesCall.uploadAuthorImage(formData, getFormDataConfig(), navigate);
            setAuthImagrPath(response.data.path);
            setError(null);
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to submit news.');
        } finally {
            setImageLoading(false)
        }

    }

    return (
        <CommonLayout>
            <div className="container mt-5" style={{ marginBottom: '6rem' }}>
                <h1 className="text-center mb-4"
                    style={{
                        textAlign: "center",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "2.8rem",
                        fontWeight: "600",
                        letterSpacing: "1.5px",
                        textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                        marginBottom: "5%",
                        background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
                        WebkitBackgroundClip: "text",
                    }}>Add News</h1>
                <label className="form-label">Select Genre for News:</label>
                <select
                    name="genre"
                    value={selectedGenre}
                    onChange={handleGenreChange}
                    className="form-select mb-2"
                    required
                >
                    <option value="">Select Genre</option>
                    {genres.map((genre) => (
                        <option key={genre._id} value={genre.genreName}>
                            {genre.genreName}
                        </option>
                    ))}
                </select>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <JoditEditor
                            ref={editor}
                            value={description}
                            onChange={setDescription}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Author Picture </label>
                        <input
                            type="file"
                            onChange={handleAuthImageChange}
                            className="form-control"
                            accept="image/*"
                        />
                        {authImagePath && (
                            <img src={authImagePath} alt="Cover Preview" className="img-thumbnail cover-preview mt-3"
                                style={{ height: "200px", objectFit: "cover" }}
                            />
                        )}
                        {imageLoading && (
                            <div className="d-flex justify-content-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Author</label>
                        <input
                            type="text"
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                            className="form-control"
                        />
                    </div>
                    {/* New fields for Read Time, Instagram, Author Pic, and Author Designation */}
                    <div className="mb-3">
                        <label className="form-label">Read Time (minutes)</label>
                        <input
                            type="number"
                            value={readTime}
                            onChange={(e) => setReadTime(e.target.value)}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Instagram Handle</label>
                        <input
                            type="text"
                            value={instagram}
                            onChange={(e) => setInstagram(e.target.value)}
                            className="form-control"
                        />
                    </div>


                    <div className="mb-3">
                        <label className="form-label">Cover Image</label>
                        <input
                            type="file"
                            onChange={handleImageChange}
                            className="form-control"
                            accept="image/*"
                        />
                        {coverImagePath && (
                            <img src={coverImagePath} alt="Cover Preview" className="img-thumbnail cover-preview mb-3"
                                style={{ height: "200px", objectFit: "cover" }}
                            />
                        )}
                        {imageLoading && (
                            <div className="d-flex justify-content-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="mb-3">
                        <label className="form-label">Author Designation</label>
                        <input
                            type="text"
                            value={authorDesignation}
                            onChange={(e) => setAuthorDesignation(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-primary" disabled={imageLoading}>
                            {imageLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </>
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </CommonLayout>
    );
};

export default AddNews;
