import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CommonLayout from '../../../layouts/CommonLayout';
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig, getFormDataConfig } from '../../../services/apiUtils';
import { toast } from 'react-toastify';


const AddQuiz = () => {
    const [csvFile, setCsvFile] = useState(null);
    const [quizData, setQuizData] = useState({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: '',
        genre: ''
    });
    const [genres, setGenres] = useState([]);

    // Fetch genres on component mount
    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const response = await allAPiServicesCall.getGenres(getAuthConfig())
                setGenres(response.data);
            } catch (error) {
                console.error('Error fetching genres:', error);
            }
        };

        fetchGenres();
    }, []);

    const handleCsvUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('genre', quizData.genre); 
            try {
                await allAPiServicesCall.uploaduizCsv(formData, getFormDataConfig())
            } catch (error) {
                toast.error('Failed to upload CSV');
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQuizData({ ...quizData, [name]: value });
    };

    const handleSingleQuizSubmit = async (e) => {
        e.preventDefault();
        try {
            await allAPiServicesCall.addQuiz(quizData, getAuthConfig());
            setQuizData({ question: '', optionA: '', optionB: '', optionC: '', optionD: '', correctAnswer: '', genre: '' }); // Reset form
        } catch (error) {
            toast.error('Failed to add quiz');
        }
    };

    return (
        <CommonLayout>
            <div className="container">
                <h2 className='text-center' style={{
                    textAlign: "center",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "2.8rem",
                    fontWeight: "600",
                    letterSpacing: "1.5px",
                    textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                    marginBottom: "5%",
                    background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
                    WebkitBackgroundClip: "text",
                }}>Add Quiz</h2>

                <div className="">
                    <label className="form-label">Select Genre for CSV Upload:</label>
                    <select
                        name="genre"
                        value={quizData.genre}
                        onChange={handleInputChange}
                        className="form-select mb-2"
                        required
                    >
                        <option value="">Select Genre</option>
                        {genres.map((genre) => (
                            <option key={genre._id} value={genre.genreName}>
                                {genre.genreName}
                            </option>
                        ))}
                    </select>

                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleCsvUpload}
                        className="form-control mb-4"
                    />
                    {/* <button className="btn btn-primary" onClick={() => document.getElementById('csv-upload').click()}>
                        Upload CSV
                    </button> */}
                </div>

                {/* <form onSubmit={handleSingleQuizSubmit} className="border p-4 rounded bg-light">
                    <h3>Add Quiz One by One</h3>

                    <div className="mb-3">
                        <label className="form-label">Question:</label>
                        <input
                            type="text"
                            name="question"
                            value={quizData.question}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Option A:</label>
                        <input
                            type="text"
                            name="optionA"
                            value={quizData.optionA}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Option B:</label>
                        <input
                            type="text"
                            name="optionB"
                            value={quizData.optionB}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Option C:</label>
                        <input
                            type="text"
                            name="optionC"
                            value={quizData.optionC}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Option D:</label>
                        <input
                            type="text"
                            name="optionD"
                            value={quizData.optionD}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Correct Answer:</label>
                        <input
                            type="text"
                            name="correctAnswer"
                            value={quizData.correctAnswer}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Genre:</label>
                        <select
                            name="genre"
                            value={quizData.genre}
                            onChange={handleInputChange}
                            className="form-select"
                            required
                        >
                            <option value="">Select Genre</option>
                            {genres.map((genre) => (
                                <option key={genre._id} value={genre.genreName}>
                                    {genre.genreName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type="submit" className="btn btn-success">Add Quiz</button>
                </form> */}
                <div className='' >
                    <form
                        onSubmit={handleSingleQuizSubmit}
                        className="border rounded bg-light p-2  "
                    >
                        <h3 className="mb-4">Add Quiz One by One</h3>

                        <div className="mb-3">
                            <label className="form-label">Question:</label>
                            <input
                                type="text"
                                name="question"
                                value={quizData.question}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Option A:</label>
                            <input
                                type="text"
                                name="optionA"
                                value={quizData.optionA}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Option B:</label>
                            <input
                                type="text"
                                name="optionB"
                                value={quizData.optionB}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Option C:</label>
                            <input
                                type="text"
                                name="optionC"
                                value={quizData.optionC}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Option D:</label>
                            <input
                                type="text"
                                name="optionD"
                                value={quizData.optionD}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Correct Answer:</label>
                            <input
                                type="text"
                                name="correctAnswer"
                                value={quizData.correctAnswer}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Genre:</label>
                            <select
                                name="genre"
                                value={quizData.genre}
                                onChange={handleInputChange}
                                className="form-select"
                                required
                            >
                                <option value="">Select Genre</option>
                                {genres.map((genre) => (
                                    <option key={genre._id} value={genre.genreName}>
                                        {genre.genreName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button type="submit" className="btn btn-success w-100 mb-3">
                            Add Quiz
                        </button>
                    </form>
                </div>
            </div>
        </CommonLayout>
    );
};

export default AddQuiz;