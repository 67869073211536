import React, { useEffect, useState } from "react";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { Button, ButtonGroup, Form, Spinner } from "react-bootstrap";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import './Playlist.css'; // Assuming you save your CSS styles in this file
import EditPlaylistModal from "../../../components/Model/EditPlaylistModel"

function Playlist() {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [playlistToDelete, setPlaylistToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [playlistEditData, setPlaylistEditData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllAdminPlaylist = async () => {
      setLoading(true);

      try {
        let response = await allAPiServicesCall.getAllAdminPlaylist(
          {},
          getAuthConfig()
        );


        if (Array.isArray(response.data)) {
          setPlaylists(response.data);
        } else {
          console.warn("No data found or data is not an array");
          setError("No data found or data is not an array");
        }
      } catch (error) {
        console.error("Error fetching playlists:", error);
        setError("Error fetching playlists");
      } finally {
        setLoading(false);
      }
    };

    fetchAllAdminPlaylist();
  }, []);

  const handleCreatePlaylist = () => {
    navigate("/create_playlist");
  };

  const handleDeletePlaylist = (playlistId) => {
    setPlaylistToDelete(playlistId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!playlistToDelete) return;

    try {
      await allAPiServicesCall.deleteAdminPlaylist(
        { playlist_id: playlistToDelete },
        getAuthConfig()
      );

      setPlaylists(playlists.filter((playlist) => playlist._id !== playlistToDelete));

      setShowDeleteModal(false);
      setPlaylistToDelete(null);
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  };

  const handleEditPlayList = (playlistID) => {
    setShowEditModal(true)
    setPlaylistEditData(playlistID)
  }

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };


  const filteredPlaylists = playlists.filter((playlist) =>
    playlist.playlist_name.toLowerCase().includes(searchTerm)
  );

  return (
    <CommonLayout>
      <div className="container my-5 pb-5 pt-2">
        <h3 style={{
          textAlign: "center",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "2.8rem",
          fontWeight: "600",
          letterSpacing: "1.5px",
          textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
          marginBottom: "10px",
          background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
          WebkitBackgroundClip: "text",
        }} className="playlist-heading">ALL PLAYLIST</h3>

        <Form.Group controlId="searchAlbum">
          <Form.Control
            type="text"
            placeholder="Search for a playlist..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="search-album-input mb-4"
          />
        </Form.Group>

        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <p>{error}</p>
        ) : filteredPlaylists.length === 0 ? (
          <div className="no-playlists">
            <p>No playlists found. You can create a new playlist.</p>
            <Button variant="primary" onClick={handleCreatePlaylist}>
              Create Playlist
            </Button>
          </div>
        ) : (
          <div className="playlist-card-container">
            {filteredPlaylists.map((playlist) => (
              <div key={playlist._id} className="playlist-card">
                <img
                  className="playlist-card-image"

                  src={playlist.playlist_image}
                  alt={playlist.title}
                />
                <div className="playlist-card-content">
                  <h3 className="playlist-card-title text-center">{playlist.playlist_name}</h3>
                  <p className="playlist-card-description text-center">
                    Total Songs: {playlist.song_count}
                  </p>

                  <ButtonGroup className="playlist-button-group">
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(`/playlist/${playlist._id}`);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      variant="info"
                      onClick={() => handleEditPlayList(playlist)}
                    >
                      Edit
                    </Button>

                    <Button
                      variant="danger"
                      onClick={() => handleDeletePlaylist(playlist._id)}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this playlist?"
      />



      <EditPlaylistModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        playlistDetails={playlistEditData}

      />
    </CommonLayout>
  );
}

export default Playlist;

