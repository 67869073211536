import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Image } from 'react-bootstrap';
import { allAPiServicesCall } from '../../services/apiServices';
import { getAuthConfig, getFormDataConfig } from '../../services/apiUtils';
import { toast } from 'react-toastify';

const AddBannerModal = ({ show, handleClose, call }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirectionLink, setRedirectionLink] = useState('');
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState("");

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const response = await allAPiServicesCall.getGenres(getAuthConfig());
        console.log(response.data);
        if (response.data) {
          setGenres(response.data);
        }
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };
    fetchGenres();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    console.log('Submit', selectedGenre)
    let imagepath;
    if (!title || !description || !image || !redirectionLink || !selectedGenre) {
      alert('Please fill in all fields, select a genre, and upload an image.');
      return;
    }
    if (image) {
      const formData = new FormData();
      formData.append('image', image);
      const response = await allAPiServicesCall.uploadAuthorImage(formData, getFormDataConfig());
      imagepath = response.data.path;
    }

    const apiPayload = {
      title,
      description,
      image: imagepath,
      redirection_link: redirectionLink,
      genre: selectedGenre
    };

    setLoading(true);

    try {
      const response = await allAPiServicesCall.addBanner(getAuthConfig(), apiPayload);
      if (response) {
        toast.success(response.message);
        call();
        setTitle('');
        setDescription('');
        setImage(null);
        setPreview(null);
        setSelectedGenre('');
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter banner title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter banner description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Upload Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {preview && (
              <div className="mt-3">
                <strong>Preview:</strong>
                <Image src={preview} alt="Preview" fluid className="mt-2" />
              </div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Redirection Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter redirection link"
              value={redirectionLink}
              onChange={(e) => setRedirectionLink(e.target.value)}
            />
          </Form.Group>

          <Form.Select
            value={selectedGenre}
            onChange={(e) => setSelectedGenre(e.target.value)}
          >
            <option value="">Select a genre</option>
            {genres.map((genre) => (
              <option key={genre._id} value={genre.genreName}>
                {genre.genreName}
              </option>
            ))}
          </Form.Select>

          <Button variant="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' style={{ width: "1rem", height: "1rem" }}></span>
            ) : 'Submit'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddBannerModal;
