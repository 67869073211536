import React, { useEffect, useState } from 'react';
import CommonLayout from '../../../layouts/CommonLayout';
import { Card, Row, Col, Container, Spinner, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig } from '../../../services/apiUtils';
import AddBannerModel from '../../../components/Model/AddBannerModel';
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import EditBannerModel from '../../../components/Model/EditBannerModel';
import { toast } from 'react-toastify';
const BannerSection = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAddBannerModal, setShowAddBannerModal] = useState(false);
    const [showDeleteBanner, setShowDeleteBannerModal] = useState(false);
    const [deleteBannerId, setDeleteBannerId] = useState('');
    const [showEditBannerModal, setShowEditBannerModal] = useState(false);
    const [editBannerData, setEditBannerData] = useState({});

    const fetchBanners = async () => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.getBanners(getAuthConfig());
            setBanners(response.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBanners();
    }, []);

    const toggleStatus = async (bannerId, currentIsActive) => {
        setLoading(true);
        try {
            const updatedIsActive = !currentIsActive;

            const response = await allAPiServicesCall.toggleStatusFun(getAuthConfig(), { bannerId, isActive: updatedIsActive });

            if (response) {
                setBanners((prevBanners) =>
                    prevBanners.map((banner) =>
                        banner._id === bannerId ? { ...banner, isActive: updatedIsActive } : banner
                    )
                );
            }
        } catch (error) {
            console.error('Error updating banner status:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteBannerCard = async () => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.deleteBanner(getAuthConfig(), { bannerId: deleteBannerId._id });
            if (response) {
                toast.success(response.message);
                setShowDeleteBannerModal(false);
                fetchBanners();
            } else {
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            console.error("Error deleting banner:", error);
            alert("An error occurred while deleting the banner. Please check the console for details.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <CommonLayout>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <Container className="mt-5 pt-5">
                    <h1 className="text-center mb-4">Banners</h1>
                    <Button className='d-flex text-center  m-auto mt-2' onClick={() => {
                        setShowAddBannerModal(true);
                    }}>Add Banner</Button>
                    <Row xs={1} md={2} lg={3} className="g-4 mb-5 pb-5">
                        {banners.map((banner) => (
                            <Col key={banner._id}>
                                <Card className="h-100 shadow-sm ">
                                    <Card.Img
                                        variant="top"
                                        src={banner.image}
                                        alt={banner.title}
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />
                                    <Card.Body>
                                        <Card.Title>{banner.title}</Card.Title>
                                        <Card.Text>{banner.description}</Card.Text>
                                        <Card.Text className='fw-bold'>Genre : {banner?.genre}</Card.Text>
                                        <div className='d-flex justify-content-between'>
                                            <Form.Check
                                                type="switch"
                                                id={`status-switch-${banner._id}`}
                                                label={banner.isActive ? 'Active' : 'Inactive'}
                                                checked={banner.isActive}
                                                onChange={() => toggleStatus(banner._id, banner.isActive)}
                                            />
                                            <Row>
                                                <Col className='p-0'>
                                                    <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => {
                                                        setEditBannerData(banner);
                                                        setShowEditBannerModal(true);
                                                    }}><MdOutlineModeEdit color='black' size={19} /></Button>
                                                </Col>
                                                <Col className='p-0'>
                                                    <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => {
                                                        setDeleteBannerId(banner);
                                                        setShowDeleteBannerModal(true);
                                                    }}><MdDelete color='red' size={19} /></Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        {new Date(banner.createdAt).toLocaleDateString()}
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {
                        showAddBannerModal && (<AddBannerModel show={showAddBannerModal} handleClose={() => setShowAddBannerModal(false)} call={fetchBanners} />)
                    }
                    {
                        showDeleteBanner && (<ConfirmationModal show={showDeleteBanner} handleClose={() => setShowDeleteBannerModal(false)} onConfirm={deleteBannerCard} message="Are you sure you want to delete this banner?" />)
                    }
                    {
                        showEditBannerModal && (<EditBannerModel show={showEditBannerModal} handleClose={() => setShowEditBannerModal(false)} call={fetchBanners} banner={editBannerData} />)
                    }
                </Container>
            )}
        </CommonLayout>
    );
};

export default BannerSection;
