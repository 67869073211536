import React, { useState, useEffect } from 'react';
import CommonLayout from '../../../layouts/CommonLayout';
import { Modal, Button, Spinner } from 'react-bootstrap'; // Added Spinner for loading
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig } from '../../../services/apiUtils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './ContributeToLyricsList.css';

const ContributeToLyricsList = () => {
    const [contributions, setContributions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLyrics, setSelectedLyrics] = useState(null);
    const navigate = useNavigate();

    const fetchLyrics = async () => {
        setLoading(true);
        try {
            const fetchContributions = await allAPiServicesCall.getAllContributeToLyrics(getAuthConfig());
            if (fetchContributions.status === 200) {
                setContributions(fetchContributions.data);
            } else {
                setContributions([]);
            }
        } catch (error) {
            console.error("Error fetching contributions:", error);
            setError('Error fetching contributions');
        } finally {
            setLoading(false);
        }
    };

    const fetchLyricsDetails = async (lyricsId) => {
        setLoading(true);
        try {
            const response = await allAPiServicesCall.getLyricsById(getAuthConfig(), lyricsId);
            setSelectedLyrics(response.data);
            setModalOpen(true);
        } catch (error) {
            setError('Error fetching lyrics details');
        } finally {
            setLoading(false);
        }
    };

    const handleApproveOrReject = async (lyricsId, status) => {
        setLoading(true);
        try {
            const payload = {
                contributionId: lyricsId,
                status: status,
            };
            await allAPiServicesCall.approveOrrejectLyrics(payload, getAuthConfig(), navigate);
            setModalOpen(false);
            fetchLyrics(); // Refresh the contributions list
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLyrics();
    }, []);

    return (
        <CommonLayout>
            <div className="container" style={{ marginTop: '60px' }}>
                <h2
                    style={{
                        textAlign: 'center',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: '2.8rem',
                        fontWeight: '600',
                        letterSpacing: '1.5px',
                        textShadow: '3px 3px 8px rgba(0, 0, 0, 0.3)',
                        marginBottom: '10px',
                        background: '-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)',
                        WebkitBackgroundClip: 'text',
                    }}
                >
                    Contributions List
                </h2>
                {loading ? (
                    <div className="text-center my-5">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : contributions.length > 0 ? (
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Song Name</th>
                                    <th>Lyrics</th>
                                    <th>Language</th>
                                    <th>Release Year</th>
                                    <th>Genres</th>
                                    <th>Performed By</th>
                                    <th>Added By</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contributions.map((contribution) => (
                                    <tr key={contribution._id}>
                                        <td>{contribution.songName}</td>
                                        <td>{contribution.lyrics.substring(0, 50)}...</td>
                                        <td>{contribution.language}</td>
                                        <td>{contribution.releaseYear}</td>
                                        <td>{contribution.genres.join(', ')}</td>
                                        <td>{contribution.performedBy}</td>
                                        <td>{contribution.user_data.username}</td>
                                        <td
                                            style={{
                                                color:
                                                    contribution.status === 'Approved'
                                                        ? 'green'
                                                        : contribution.status === 'Rejected'
                                                            ? 'yellow'
                                                            : 'black',
                                            }}
                                        >
                                            {contribution.status}
                                        </td>
                                        <td>
                                            <div className="d-flex gap-3">
                                                <button
                                                    className="btn btn-info btn-sm"
                                                    onClick={() => fetchLyricsDetails(contribution._id)}
                                                    disabled={loading}
                                                >
                                                    View
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm ml-2"
                                                    disabled={loading}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-center">No contributions found.</p>
                )}
            </div>

            {/* Modal Component */}
            {modalOpen && selectedLyrics && (
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedLyrics.songName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <p><strong>Song:</strong> {selectedLyrics.songName}</p>
                        <p><strong>Lyrics:</strong></p>
                        <p>
                            {selectedLyrics.lyrics.split("\\n").map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                        <p><strong>Language:</strong> {selectedLyrics.language}</p>
                        <p><strong>Release Year:</strong> {selectedLyrics.releaseYear}</p>
                        <p><strong>Genres:</strong> {selectedLyrics.genres.join(', ')}</p>
                        <p><strong>Performed By:</strong> {selectedLyrics.performedBy}</p>
                        <p><strong>Status:</strong> {selectedLyrics.status}</p>
                        <p><strong>Artist Bio:</strong> {selectedLyrics.artistBio}</p>
                        <p>
                            <a href={selectedLyrics.youtubeURL} target="_blank" rel="noopener noreferrer">Watch on YouTube</a>
                        </p>
                        <p>
                            <a href={selectedLyrics.artistWebsite} target="_blank" rel="noopener noreferrer">Artist Website</a>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        {selectedLyrics.status === 'Pending' && (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() => handleApproveOrReject(selectedLyrics._id, 'approved')}
                                    disabled={loading}
                                >
                                    Approve
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleApproveOrReject(selectedLyrics._id, 'rejected')}
                                    disabled={loading}
                                >
                                    Reject
                                </Button>
                            </>
                        )}
                        <Button
                            variant="secondary"
                            onClick={() => setModalOpen(false)}
                            disabled={loading}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </CommonLayout>
    );
};

export default ContributeToLyricsList;
