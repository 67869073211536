import React, { useState, useEffect } from "react";
import CommonLayout from "../../../layouts/CommonLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Spinner,
  Container,
  Modal,
} from "react-bootstrap";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import "./RecommendedArtist.css";

const RecommendedArtist = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [recommendedArtists, setRecommendedArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debounceQuery, setDebounceQuery] = useState(searchQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(20); // Page limit
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal state
  const [artistToDelete, setArtistToDelete] = useState(null); // Artist to delete

  const fetchSearchResults = async (query) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getSearchArtist(
        { query },
        getAuthConfig()
      );
      const searchList = response.data || [];
      setSearchResults(searchList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching search artist:", error);
      toast.error("Failed to fetch search artists");
    }
  };

  const fetchRecommendedArtists = async (page) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getRecommendedArtists(
        { limit, page },
        getAuthConfig()
      );
      const { recommendedList, totalPages } = response.data;
      setRecommendedArtists(recommendedList);
      setTotalPages(totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching recommended artists:", error);
      toast.error("Failed to fetch recommended artists");
    }
  };

  const addArtistToTrends = async (artistId) => {
    setLoading(true);
    try {
      await allAPiServicesCall.addTrendingArtist(
        { artist_id: artistId },
        getAuthConfig()
      );
      setSearchQuery("")
      navigate("/trending-artist");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add to trending");
    }
  };

  const addArtistToRecommended = async (artistId) => {
    setLoading(true);
    try {
      await allAPiServicesCall.addRecommendedArtist(
        { artist_id: artistId },
        getAuthConfig()
      );
      setSearchQuery("")
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add to recommended");
    }
  };

  const handleOnDelete = async () => {
    setLoading(true);
    try {
      await allAPiServicesCall.deleteRecommendedArtist(
        { id: artistToDelete },
        getAuthConfig()
      );
      fetchRecommendedArtists(currentPage);
      setShowDeleteModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to remove artist from recommended");
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => setDebounceQuery(searchQuery), 500);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    if (debounceQuery.trim()) {
      fetchSearchResults(debounceQuery);
    } else {
      fetchRecommendedArtists(currentPage);
    }
  }, [debounceQuery, currentPage]);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const confirmDelete = (artistId) => {
    setArtistToDelete(artistId);
    setShowDeleteModal(true);
  };

  return (
    <CommonLayout>
      <Container className="container mt-5 pt-2">
        <h3 style={{
          textAlign: "center",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "2.8rem",
          fontWeight: "600",
          letterSpacing: "1.5px",
          textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
          marginBottom: "10px",
          background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
          WebkitBackgroundClip: "text",
        }}>RECOMMENDED ARTISTS</h3>

        <Form.Group controlId="searchAlbum">
          <Form.Control
            type="text"
            placeholder="Search for artists to add to trending or recommended..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-album-input"
          />
        </Form.Group>

        <div className="search-results-container">
          {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Row xs={1} md={4} lg={4} className="g-2 ">
              {searchQuery.trim() ? (
                searchResults.length > 0 ? (
                  searchResults.map((result) => (
                    <Col key={result.artist_id}>
                      <Card className="card-content" >
                        <Card.Img
                          variant="top"
                          src={result?.artist_image_link || "/assets/logo.png"}
                          alt={result?.artist_name || "Artist Image"}
                        />
                        <Card.Body>
                          <Card.Title>{result?.artist_name}</Card.Title>
                          <div className="btn-container">
                            <Button
                              variant="primary"
                              className="trending-btn"
                              onClick={() => addArtistToTrends(result.artist_id)}
                            >
                              Trending
                            </Button>
                            <Button
                              variant="primary"
                              className="recommended-btn"
                              onClick={() =>
                                addArtistToRecommended(result.artist_id)
                              }
                            >
                              Recommended
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div className="empty-message">No results found</div>
                )
              ) : (
                recommendedArtists.map((artist) => (
                  <Col key={artist.artist_id}>
                    <Card className="card-content  h-100">
                      <Card.Img
                        onClick={() => navigate(`/artist/albums-songs/${artist.artist_id}`)}
                        style={{
                          cursor: "pointer",
                        }}
                        variant="top"
                        src={artist.artist_image_link}
                        alt={artist?.artist_name || "Artist Image"}
                      />
                      <Card.Body>
                        {/* <Card.Title>{artist?.artist_name}</Card.Title> */}
                        <ReadMoreText text={artist?.artist_name || "No Album Name"} />
                        <Button
                          variant="danger"
                          onClick={() => confirmDelete(artist?._id)}
                        >
                          Delete
                        </Button>
                        {/* <Button
                          variant="success"
                          onClick={() => navigate(`/artist/albums-songs/${artist.artist_id}`)}
                        >
                          View
                        </Button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          )}
        </div>

        {recommendedArtists.length > 0 && (
          <div className="pagination-container">
            <Button onClick={() => setCurrentPage((p) => p - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            <span>{currentPage} / {totalPages}</span>
            <Button onClick={() => setCurrentPage((p) => p + 1)} disabled={currentPage === totalPages}>
              Next
            </Button>
          </div>
        )}

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this artist?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleOnDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </CommonLayout>
  );
};


//read more text
const ReadMoreText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 20; // Maximum characters before truncating
  const truncatedText = text.length > maxLength && !isExpanded
    ? `${text.slice(11, maxLength)}..`
    : text;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card.Title>
      {truncatedText}
      {text.length > maxLength && (
        <span className="fs-6"
          style={{ color: "blue", cursor: "pointer", marginLeft: "3px", whiteSpace: "no-wrap" }}
          onClick={toggleExpanded}
        >
          {isExpanded ? "read less" : "Read more"}
        </span>
      )}
    </Card.Title>
  );
};


export default RecommendedArtist;

