import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import CommonLayout from "../../../layouts/CommonLayout";
import { useParams } from "react-router-dom";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import sanitizeHtml from "sanitize-html";
import "./NewsDetails.css";

function NewsDetails() {
  const [newsDetails, setNewsDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { newsId } = useParams();

  const getNews = async () => {
    try {
      const response = await allAPiServicesCall.getNewsDetails(
        { newsId },
        getAuthConfig()
      );
      setNewsDetails(response?.data);
    } catch (error) {
      console.error("Error fetching news details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, [newsId]);

  return (
    <CommonLayout>
    
      <div className="news-details-wrapper container  ">
     
      {/* <h3
          style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "5%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }}
        >MOST PLAYED SONG</h3> */}
        <Card className="news-card   mt-5 ">
          {loading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              <Card.Img
                variant="top"
                src={
                  newsDetails.coverImg ||
                  "/assets/musical-stave-with-hearts-plain-background.jpg"
                }
                className="news-card-img  text-center ms-2 rounded-3 mt-1"
                                 
              />
              <Card.Body className="news-card-body">
                <Card.Title className="news-title">
                  {newsDetails.title}
                </Card.Title>
                <Card.Text
                  className="news-description"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(newsDetails.description),
                  }}
                />
                <p className="news-author">
                  <strong>Author:</strong> {newsDetails.author}
                </p>
                <p className="news-publish-date">
                  <strong>Published on:</strong> {newsDetails.publishDate}
                </p>
              </Card.Body>
            </>
          )}
        </Card>
      </div>
    </CommonLayout>
  );
}

export default NewsDetails;
