// *******************************ADD-TESTIMONIAL******************************************
import React, { useState } from "react";
import axios from "axios";

const AddTestimonial = () => {
  const [fullName, setFullName] = useState("");
  const [rating, setRating] = useState("");
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullName || !rating || !description || !avatar) {
      setErrorMessage("All fields are required.");
      return;
    }

    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios.post(
        "https://lyricsweb.com/api/v1/admin/upload-testimonial-avatar",
        {
          fullName,
          rating,
          description,
          avatar,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.status === 201) {
        setSuccessMessage("Testimonial added successfully!");
        setFullName("");
        setDescription("");
        setRating("");
        setAvatar("");
      }
    } catch (error) {
      console.error("Error adding testimonial:", error);
      setErrorMessage("Failed to add testimonial. Please try again.");
    }
  };

  return (
    <div>
      <style>
        {`
          .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #f9f9f9;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          h2 {
            text-align: center;
            margin-bottom: 20px;
          }
          .form-group {
            margin-bottom: 15px;
          }
          .form-control {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .btn {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          .btn:hover {
            background-color: #0056b3;
          }
          .alert {
            margin-bottom: 15px;
            padding: 10px;
            color: white;
            border-radius: 4px;
          }
          .alert-success {
            background-color: #28a745;
          }
          .alert-danger {
            background-color: #dc3545;
          }
        `}
      </style>

      <div className="container">
        <h2>Add Testimonial</h2>

        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="form-control"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Enter your name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="rating">Rating</label>
            <input
              type="number"
              id="rating"
              className="form-control"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
              min="1"
              max="5"
              placeholder="Rate between 1 and 5"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Testimonial description</label>
            <textarea
              id="description"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              placeholder="Write your testimonial"
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="avatar">Avatar URL</label>
            <input
              type="url"
              id="avatar"
              className="form-control"
              value={avatar}
              onChange={(e) => setAvatar(e.target.value)}
              placeholder="Enter avatar image URL"
            />
          </div>

          <button type="submit" className="btn">
            Add Testimonial
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddTestimonial;
