// import React, { useState } from "react";
// import {
//   CDBInput,
//   CDBCard,
//   CDBCardBody,
//   CDBBtn,
//   CDBContainer,
//   CDBLink,
// } from "cdbreact";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CommonLayout from "../../../layouts/CommonLayout";
// import "./LoginPage.css";
// import { allAPiServicesCall } from "../../../services/apiServices";
// import { getConfig } from "../../../services/apiUtils";
// import { useNavigate } from "react-router-dom";

// const LoginPage = () => {
// const [email, setEmail] = useState("");
// const [password, setPassword] = useState("");
// const [showPassword, setShowPassword] = useState(false);
// const [errors, setErrors] = useState({});
// const [loading, setLoading] = useState(false);

// const navigate = useNavigate();

// const togglePasswordVisibility = () => {
//   setShowPassword(!showPassword);
// };

// const validate = () => {
//   const errors = {};
//   if (!email) {
//     errors.email = "Email is required";
//   } else if (!/\S+@\S+\.\S+/.test(email)) {
//     errors.email = "Email is invalid";
//   }

//   if (!password) {
//     errors.password = "Password is required";
//   }

//   return errors;
// };

// const handleOnSubmit = async (e) => {
//   e.preventDefault();
//   const errors = validate();
//   if (Object.keys(errors).length > 0) {
//     setErrors(errors);
//     return;
//   }

//   setLoading(true);
//   try {
//     await allAPiServicesCall.loginAdmin(
//       { email, password },
//       getConfig(),
//       navigate
//     );
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setLoading(false);
//   }
// };

//   return (
//     <>
//       <div
//         className="main-header-login text-center my-5"
//         style={{ borderRadius: "5px" }}
//       >
//         {/* <h1 className='text-primary'>Lyricsweb.com</h1> */}
//         <img src="/assets/logo.png" alt="" />
//       </div>
//       {loading ? (
//         <div className="spinner-container">
//           <div className="spinner"></div>
//         </div>
//       ) : (
//         <div className="d-flex justify-content-center align-items-center mt-4 mb-2 h-100">
//           <CDBContainer>
//             <CDBCard
//               className="mx-auto"
//               style={{
//                 maxWidth: "30rem",
//                 width: "100%",
//                 borderRadius: "10px", // Adjust the border-radius as needed
//                 backgroundColor: "#f0f0f0", // Light gray background color
//               }}
//             >
//               <CDBCardBody className="mx-4">
//                 <div className="text-center mt-4 mb-2">
//                   <p className="h4 font-weight-bold">Sign in</p>
//                 </div>
//                 <CDBInput
//                   label="E-mail"
//                   type="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//                 {errors.email && (
//                   <div className="text-danger">{errors.email}</div>
//                 )}
//                 <div style={{ position: "relative" }}>
//                   <CDBInput
//                     label="Password"
//                     type={showPassword ? "text" : "password"}
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                   <FontAwesomeIcon
//                     icon={showPassword ? faEyeSlash : faEye}
//                     onClick={togglePasswordVisibility}
//                     className="password-toggle-icon"
//                     style={{
//                       position: "absolute",
//                       top: "70%",
//                       right: "12px",
//                       transform: "translateY(-50%)",
//                       cursor: "pointer",
//                     }}
//                   />
//                 </div>
//                 {errors.password && (
//                   <div className="text-danger">{errors.password}</div>
//                 )}
//                 <p className="mt-n3 text-end">
//                   <CDBLink
//                     className="p-0 forget-password"
//                     to="/forget-password"
//                   >
//                     Forgot Password?
//                   </CDBLink>
//                 </p>
//                 <CDBBtn
//                   color="dark"
//                   onClick={(e) => handleOnSubmit(e)}
//                   className="btn-block my-4 mx-auto login-button"
//                 >
//                   Sign in
//                 </CDBBtn>
//                 <hr />
//               </CDBCardBody>
//             </CDBCard>
//           </CDBContainer>
//         </div>
//       )}
//     </>
//   );
// };

// export default LoginPage;


// import React, { useState } from 'react';
// import {
//   MDBContainer,
//   MDBInput,
//   MDBCheckbox,
//   MDBBtn,
// }
//   from 'mdb-react-ui-kit';
// import { useNavigate } from 'react-router-dom';
// import { allAPiServicesCall } from "../../../services/apiServices";
// import { getConfig } from '../../../services/apiUtils';


// function LoginPage() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const validate = () => {
//     const errors = {};
//     if (!email) {
//       errors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(email)) {
//       errors.email = "Email is invalid";
//     }

//     if (!password) {
//       errors.password = "Password is required";
//     }

//     return errors;
//   };

//   const handleOnSubmit = async (e) => {
//     e.preventDefault();
//     const errors = validate();
//     if (Object.keys(errors).length > 0) {
//       setErrors(errors);
//       return;
//     }

//     setLoading(true);
//     try {
//       await allAPiServicesCall.loginAdmin(
//         { email, password },
//         getConfig(),
//         navigate
//       );
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   return (
//     <MDBContainer className="pt-5 my-5 d-flex flex-column w-25">
//       <img src="/assets/Group.png" alt="Logo" />
//       <h3 className="text-center py-3">Admin Login</h3>
//       <MDBInput wrapperClass='mb-4' label='Email address' id='form1' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
//       <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />

//       <div className="d-flex justify-content-between mx-3 mb-4">
//         <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
//         <a href="!#">Forgot password?</a>
//       </div>

//       <MDBBtn className="mb-4" onClick={(e) => handleOnSubmit(e)}>Sign in</MDBBtn>

//     </MDBContainer>
//   );
// }

// export default LoginPage;



import React, { useState } from 'react';
import {
  MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
}
  from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import { allAPiServicesCall } from "../../../services/apiServices";
import { getConfig } from '../../../services/apiUtils';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }

    return errors;
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    try {
      await allAPiServicesCall.loginAdmin(
        { email, password },
        getConfig(),
        navigate
      );
    } catch (error) {
      console.error(error);
      setErrors({ api: "Invalid email or password" });
    } finally {
      setLoading(false);
    }
  };

  return (
    // <MDBContainer className="pt-5 my-5 d-flex flex-column w-25">
    //   <img src="/assets/Group.png" alt="Logo" />
    //   <h3 className="text-center py-3">Admin Login</h3>

    //   {errors.email && <div className="text-danger">{errors.email}</div>}
    //   <MDBInput
    //     wrapperClass="mb-4"
    //     label="Email address"
    //     id="form1"
    //     type="email"
    //     value={email}
    //     onChange={(e) => {
    //       setEmail(e.target.value);
    //       setErrors((prev) => ({ ...prev, email: "" }));
    //     }}
    //     onBlur={() => setErrors((prev) => ({ ...prev, email: validate().email }))}
    //   />



    //   {errors.password && <div className="text-danger">{errors.password}</div>}
    // <div className="position-relative mb-4">

    //     <MDBInput
    //       className="form-control"
    //        label="Password"
    //       type={showPassword ? "text" : "password"}
    //       value={password}
    //       onChange={(e) => {
    //         setPassword(e.target.value);
    //         setErrors((prev) => ({ ...prev, password: "" }));
    //       }}
    //       onBlur={() => setErrors((prev) => ({ ...prev, password: validate().password }))}
    //       placeholder="Password"
    //       style={{ paddingRight: "40px" }} 
    //     />

    //     <FontAwesomeIcon
    //       icon={showPassword ? faEyeSlash : faEye}
    //       className="position-absolute text-primary"
    //       style={{
    //         right: "10px",
    //         top: "30%",
    //         transform: "translateY(-50%)",
    //         cursor: "pointer",
    //         color: "#6c757d",
    //       }}
    //       onClick={togglePasswordVisibility}
    //     />
    //   </div>

    //   <div className="d-flex justify-content-between mx-3 mb-4">
    //     <MDBCheckbox name="flexCheck" value="" id="flexCheckDefault" label="Remember me" />
    //     <a href="!#">Forgot password?</a>
    //   </div>

    //   {errors.api && <div className="text-danger mb-3">{errors.api}</div>}

    //   <button
    //     className="mb-4 btn btn-primary"
    //     onClick={(e) => handleOnSubmit(e)}
    //     disabled={loading}
    //   >
    //     {loading ? "Signing in..." : "Sign in"}
    //   </button>
    // </MDBContainer>
    <MDBContainer
      className="pt-5 mt-5 d-flex flex-column w-100"
      style={{
        position: 'relative',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Border shadow effect
        backdropFilter: 'blur(10px)', // Background blur effect
        background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background for blur
        padding: '2rem', // Padding for the container
        overflow: 'hidden', // Prevent any child element from overflowing the container
        maxWidth: '400px', // Optional max-width to avoid stretching too wide on larger screens
      }}
    >
      <img src="/assets/Group.png" alt="Logo" style={{ width: '100%', maxWidth: '200px' }} />
      <h3
        className="text-center py-3"
        style={{
          textAlign: 'center',
          fontFamily: "'Montserrat', sans-serif",
          fontSize: '2rem',
          fontWeight: '600',
          letterSpacing: '1.5px',
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
          background: '-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)',
          WebkitBackgroundClip: 'text',
        }}
      >
        Admin Login
      </h3>

      {errors.email && <div className="text-danger">{errors.email}</div>}

      <MDBInput
        wrapperClass="mb-4"
        label="Email address"
        id="form1"
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setErrors((prev) => ({ ...prev, email: '' }));
        }}
        onBlur={() => setErrors((prev) => ({ ...prev, email: validate().email }))}
      />

      {errors.password && <div className="text-danger">{errors.password}</div>}

      <div className="position-relative mb-4">
        <MDBInput
          className="form-control"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrors((prev) => ({ ...prev, password: '' }));
          }}
          onBlur={() => setErrors((prev) => ({ ...prev, password: validate().password }))}
          placeholder="Password"
          style={{ paddingRight: '40px' }}
        />

        <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          className="position-absolute text-primary"
          style={{
            right: '10px',
            top: '30%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: '#6c757d',
          }}
          onClick={togglePasswordVisibility}
        />
      </div>

      <div className="d-flex justify-content-between mx-3 mb-4">
        <MDBCheckbox name="flexCheck" value="" id="flexCheckDefault" label="Remember me" />
        <a onClick={() => navigate("/forget-password")} className='text-primary '>Forgot password?</a>
      </div>

      {errors.api && <div className="text-danger mb-3">{errors.api}</div>}

      <button
        className="mb-4 btn btn-primary"
        onClick={(e) => handleOnSubmit(e)}
        disabled={loading}
      >
        {loading ? 'Signing in...' : 'Sign in'}
      </button>
    </MDBContainer>



  );
}

export default LoginPage;
