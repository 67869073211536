import React, { useState, useEffect } from "react";
import CommonLayout from "../../../layouts/CommonLayout";
import { useNavigate } from "react-router-dom";
import "./TopArtist.css";
import { toast } from "react-toastify";
import {
    Form,
    Button,
    Card,
    Row,
    Col,
    Spinner,
    Container,
    Modal,
} from "react-bootstrap";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";

const TopArtist = () => {
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [topArtists, setTopArtists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(20);
    const [debounceQuery, setDebounceQuery] = useState(searchQuery);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [artistToDelete, setArtistToDelete] = useState(null);

    const fetchTopArtists = async (page, limit) => {
        setLoading(true);
        try {
            let response = await allAPiServicesCall.getTopArtists(
                { limit, page },
                getAuthConfig()
            );

            const { totalPages, topArtistList } = response.data;

            if (topArtistList) {
                setTopArtists(topArtistList);
                setTotalPages(totalPages);
            } else {
                console.warn("No data found or data is not an array");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Failed to fetch top artists");
        }
    };

    const fetchSearchResults = async (query) => {
        setLoading(true);
        try {
            let response = await allAPiServicesCall.getSearchArtist(
                { query },
                getAuthConfig()
            );
            const searchList = response.data || [];
            if (searchList.length > 0) {
                setSearchResults(searchList);
            } else {
                setSearchResults([]);
                toast.info("No results found");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Failed to fetch search artists");
        }
    };

    const addArtistToTop = async (artistId) => {
        setLoading(true);
        try {
            await allAPiServicesCall.addTopArtist(
                { artist_id: artistId },
                getAuthConfig(),
            );
            setLoading(false);
            setSearchQuery("")
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    const handleOnDelete = async () => {
        if (!artistToDelete) return;
        setLoading(true);
        try {
            let payload = { id: artistToDelete };
            await allAPiServicesCall.deleteTopArtist(payload, getAuthConfig());
            fetchTopArtists(currentPage, limit);
            toast.success("Artist deleted successfully");
            setShowDeleteModal(false);
        } catch (error) {
            toast.error("Failed to delete artist.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceQuery(searchQuery);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    useEffect(() => {
        if (debounceQuery.trim()) {
            fetchSearchResults(debounceQuery);
        } else {
            fetchTopArtists(currentPage, limit);
        }
    }, [debounceQuery, currentPage, limit]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const confirmDelete = (artistId) => {
        setArtistToDelete(artistId);
        setShowDeleteModal(true);
    };

    return (
        <CommonLayout>
            <div className="my-2 p-5">
                <h3
                    style={{
                        textAlign: "center",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "2.8rem",
                        fontWeight: "600",
                        letterSpacing: "1.5px",
                        textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
                        marginBottom: "10px",
                        background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
                        WebkitBackgroundClip: "text",
                    }}
                >
                    TOP ARTISTS
                </h3>
                <Container className="add-hot-album-container">
                    <Form.Group controlId="searchAlbum">
                        <Form.Control
                            type="text"
                            placeholder="Search for artists to add to Top Artists..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search-album-input"
                        />
                    </Form.Group>

                    <div className="search-results-container">
                        {loading ? (
                            <div className="spinner-container">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (
                            <Row xs={1} md={4} lg={4} className="g-2">
                                {searchQuery.trim() ? (
                                    searchResults.length > 0 ? (
                                        searchResults.map((result) => (
                                            <Col key={result.artist_id}>
                                                <Card className="card-content">
                                                    <Card.Img
                                                        variant="top"
                                                        src={
                                                            result?.artist_image_link || "/assets/logo.png"
                                                        }
                                                        alt={result?.artist_name || "Artist Image"}
                                                    />
                                                    <Card.Body>
                                                        <Card.Title>{result?.artist_name}</Card.Title>
                                                        <Button
                                                            variant="primary"
                                                            className="top-btn"
                                                            onClick={() => addArtistToTop(result.artist_id)}
                                                        >
                                                            Add to Top
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))
                                    ) : (
                                        <div className="empty-message">No results found</div>
                                    )
                                ) : topArtists.length > 0 ? (
                                    topArtists.map((artist) => (
                                        <Col key={artist.id}>
                                            <Card className="card-content">
                                                <Card.Img
                                                    onClick={() =>
                                                        navigate(`/artist/albums-songs/${artist.artist_id}`)
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                    variant="top"
                                                    src={artist.artist_image_link}
                                                    alt={artist?.artist_name || "Artist Image"}
                                                />
                                                <Card.Body>
                                                    <Card.Title>{artist?.artist_name}</Card.Title>
                                                    <Button
                                                        variant="danger"
                                                        className="mx-2"
                                                        onClick={() => confirmDelete(artist?._id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <div className="empty-message">No top artists found</div>
                                )}
                            </Row>
                        )}
                    </div>

                    {topArtists.length > 0 && (
                        <div className="pagination-container">
                            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                                Previous
                            </Button>
                            <span>
                                {currentPage} / {totalPages}
                            </span>
                            <Button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </Button>
                        </div>
                    )}
                </Container>
            </div>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this artist?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleOnDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </CommonLayout>
    );
};

export default TopArtist;
