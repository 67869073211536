
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../components/Sidebar/Sidebar';
import Topbar from '../components/Topbar/Topbar';
import MainFooter from '../components/MainFooter/MainFooter';

const CommonLayout = ({ children }) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
 

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setMobileSidebarOpen(false); // Close mobile sidebar when resizing to desktop
      }
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    if (isMobileView) {
      setMobileSidebarOpen(!isMobileSidebarOpen);
    } else {
      setSidebarCollapsed(!isSidebarCollapsed);
    }
  };


  const closeMobileSidebar = () => {
    setMobileSidebarOpen(false);
  }
  return (
    <div className="d-flex flex-column min-vh-100" style={{ width: '100%', overflowX: 'hidden' }}>
      <div className="d-flex flex-grow-1 position-relative">
        {/* Sidebar */}
       
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          isMobileOpen={isMobileSidebarOpen}
          onClose={closeMobileSidebar}
          />
          

        {/* Main Content */}
        <div className={`flex-grow-1 d-flex flex-column ${isSidebarCollapsed ? 'main-content-collapsed' : ''}`}>
          {/* Topbar */}
          <Topbar onToggleSidebar={toggleSidebar} />

          {/* Main Content */}
          <main className="flex-grow-1 m-1" style={{ overflowX: 'hidden', }}>
            {children}
          </main>
        </div>
      </div>

      {/* Footer */}
      {/* <footer className="bg-light text-center fixed-bottom"> */}
        <MainFooter />
      {/* </footer> */}
    </div>
  );
};

export default CommonLayout;
