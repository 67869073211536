import React, { useEffect, useState } from 'react'
import CommonLayout from '../../../layouts/CommonLayout'
import { Container } from 'react-bootstrap'
import { AiFillEdit } from "react-icons/ai";
import { BiShow } from "react-icons/bi";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig } from '../../../services/apiUtils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const ArtistModal = ({ isOpen, artistDetails, closeModal }) => {
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
    <Modal show={isOpen} onHide={closeModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{artistDetails.artist_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="artist-card p-4  rounded-lg bg-white text-center">
          {/* Artist Image */}
          <div className="artist-image mb-3 ms-5">
            <img
              src={artistDetails.artist_image_link || "https://via.placeholder.com/150"}
              alt={artistDetails.artist_name}
              style={{
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                objectFit: 'cover',
                border: '2px solid #ddd'
              }}
            />
          </div>

          {/* Artist Details */}
          <div className="artist-details text-left">
            <h2 className="text-lg font-semibold">{artistDetails.artist_name}</h2>
            <p className="mt-2 text-gray-600">
              <strong>Description:</strong>{" "}
              <div className="artist-description-container mx-auto text-center" style={{ maxWidth: '600px' }}>
                <span
                  style={{ display: 'block', textAlign: 'justify', lineHeight: '1.6' }}
                  dangerouslySetInnerHTML={{ __html: artistDetails.artist_description }}
                />
              </div>
            </p>
          </div>
        </div>



      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="danger" onClick={() => navigate("/edit-artist-bio")}>
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//main componenet

const ArtistBio = () => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [ArtistBio, setArtistBio] = useState([]);
  const [page, setpage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [debounceQuery, setDebounceQuery] = useState(searchQuery);



  const navigate = useNavigate();


  const fetchArtistBio = async (page, limit) => {
    setLoading(true);
    try {
      let response = await allAPiServicesCall.getAllPlaylistBio(
        { page, limit },
        getAuthConfig()
      );

    

      if (response.success && response.status === 200) {
        setArtistBio(response.data.artistDetails);

        setTotalPages(response.data.totalPages);
      } else {
        console.warn("No data found or data is not an array");
      }
    } catch (error) {
      console.error("Error fetching artist bios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debounceQuery.trim()) {
      fetchSearchResults(debounceQuery);
    } else {
      fetchArtistBio(page, limit);
    }

  }, [page, limit, debounceQuery]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setpage((prevPage) => prevPage + 1);
    }

  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setpage((prevPage) => prevPage - 1);
    }

  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  //fetch Artist Bio Details
  const fetchArtistBioDetails = async (artistId) => {
    try {
      const response = await allAPiServicesCall.getArtistBioDetails(getAuthConfig(), artistId)
      setSelectedArtist(response.data);
      setShowViewModal(true);
    } catch (error) {
      console.log("fatch artist error", error)
    }
  };

  //search artist api
  const fetchSearchResults = async (query) => {
    setLoading(true);
    try {
      let response = await allAPiServicesCall.getSearchArtist(
        { query },
        getAuthConfig()
      );
      const searchList = response.data || [];
      if (searchList.length > 0) {
        setSearchResults(searchList);
      } else {
        setSearchResults([]);
        toast.info("No results found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch search artists");
    }
  };



  return (
    <CommonLayout>
      <Container>

        <h4
          className="mb-3 text-center"
          style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "5%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }}
        >
          Artist Bio
        </h4>

        <Form.Group controlId="searchArtist">
          <Form.Control
            type="text"
            placeholder="Search for artists..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-artist-input"
          />
        </Form.Group>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>



            <table style={styles.table} className="table table-hover">
              <thead>
                <tr>
                  <th style={styles.tableHeader}>Image</th>
                  <th style={styles.tableHeader}>Song Name</th>
                  <th style={styles.tableHeader}>Artist</th>
                  <th style={styles.tableHeader}>Action</th>
                </tr>
              </thead>
              <tbody>
                {searchQuery.trim() ? (
                  searchResults.length > 0 ? (
                    searchResults.map((result) => (
                      <tr key={result.artist_id}>
                        <td style={styles.tableHeader}>
                          <img
                            variant="top"
                            src={result?.artist_image_link || "/assets/logo.png"}
                            alt={result?.artist_name || "Artist Image"}
                            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                          />
                        </td>
                        <td style={styles.tableHeader}>{result?.artist_name}</td>
                        <td style={styles.tableHeader}>{result?.genres}</td>
                        <td style={styles.tableHeader}>
                          <BiShow
                            className="fs-3 me-3 text-primary"
                            onClick={() => fetchArtistBioDetails(result.artist_id)}
                          />
                          <AiFillEdit
                            className="fs-3 text-danger"
                            onClick={() => navigate("/edit-artist-bio")}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No results found
                      </td>
                    </tr>
                  )
                ) : ArtistBio.length > 0 ? (
                  ArtistBio.map((artist) => (
                    <tr key={artist.id}>
                      <td style={styles.tableHeader}>
                        <img
                          style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '50%' }}
                          variant="top"
                          src={artist.artist_image_link}
                          alt={artist?.artist_name || "Artist Image"}
                        />
                      </td>
                      <td style={styles.tableHeader}>{artist?.artist_name}</td>
                      <td style={styles.tableHeader}>{artist?.genres}</td>
                      <td style={styles.tableHeader}>
                        <BiShow
                          className="fs-3 me-3 text-primary"
                          onClick={() => fetchArtistBioDetails(artist.artist_id)}
                        />
                        <AiFillEdit
                          className="fs-3 text-danger"
                          onClick={() => navigate("/edit-artist-bio")}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No  artists found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>


            {/* Pagination Controls */}
            {ArtistBio.length > 0 && (
              <div className="pagination-container">
                <Button
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                >
                  Previous
                </Button>
                <span>
                  {page} / {totalPages}
                </span>
                <Button
                  onClick={handleNextPage}
                  disabled={page === totalPages}
                  style={{ cursor: page === totalPages ? 'not-allowed' : 'pointer' }}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}

      </Container>



      {/* Modal Component */}
      <ArtistModal
        isOpen={showViewModal}
        artistDetails={selectedArtist}
        closeModal={() => setShowViewModal(false)}
      />

    </CommonLayout>
  );
};

const styles = {
  container: {
    margin: "20px auto 60px auto",
    width: "80%",
  },
  heading: {
    textAlign: "center",
    fontSize: "2rem",
    marginTop: "50px",
    marginBottom: "20px",
  },
  topBar: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  searchBar: {
    width: "50%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  searchResults: {
    margin: "20px 0",
  },
  searchResultRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 10px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  songInfo: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  addButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  tableHeader: {
    padding: "10px",
    textAlign: "center",
    border: "1px solid #ddd",
  },
  viewIcon: {
    cursor: "pointer",
    color: "blue",
    fontSize: "1.5rem",
    marginRight: "10px",
  },
  editIcon: {
    cursor: "pointer",
    color: "orange",
    fontSize: "1.5rem",
    marginRight: "10px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: "red",
    fontSize: "1.5rem",
  },
  noSongsMessage: {
    textAlign: "center",
    color: "red",
    fontSize: "1.2rem",
    margin: "20px 0",
  },
};

export default ArtistBio;
