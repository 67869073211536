import React, { useEffect, useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";


import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig, getFormDataConfig } from "../../../services/apiUtils";

function EditNewsPage() {
  const { newsId } = useParams();
  const navigate = useNavigate();
  const [newsDetails, setNewsDetails] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [newImageFile, setNewImageFile] = useState(null);
  const [authImageFile, setauthImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [authimagePreview, setAuthImagePreview] = useState("");

  const editor = useRef(null);

  useEffect(() => {
    // Fetch the news details by ID
    async function fetchNewsDetails() {
      try {
        const response = await allAPiServicesCall.getNewsDetails({ newsId },
          getAuthConfig());
        setNewsDetails(response.data);
        setUpdatedData(response.data);
      } catch (error) {
        console.error("Error fetching news details:", error.response?.data || error);
      }
    }
    fetchNewsDetails();
  }, [newsId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewImageFile(file);
    const objectUrl = URL.createObjectURL(file);
    setImagePreview(objectUrl);
  };

  const uploadImageAndGetUrl = async () => {

    if (!newImageFile) return null;
    try {
      const formData = new FormData();
      formData.append("image", newImageFile);


      const response = await allAPiServicesCall.uploadNewsImage(
        formData,
        getFormDataConfig()
      );
      return response.data.path;
    } catch (error) {
      console.error("Image upload failed:", error.response?.data || error);
      return null;
    }
  };


  const uploadAuthorImageAndGetUrl = async (file) => {
    if (!authImageFile) return null;
    try {
      const formData = new FormData();
      formData.append("image", authImageFile);

      const response = await allAPiServicesCall.uploadAuthorImage(
        formData,
        // getFormDataConfig()
        {
          headers: {
            Authorization: `${localStorage.getItem('auth-token')}`,
            "Content-Type": "multipart/form-data",
          },
        },  
      );
      return response.data.path;
    } catch (error) {
      console.error("Author image upload failed:", error.response?.data || error);
      return null;
    }
  };


  const handleAuthorFileChange = (e) => {
    const file = e.target.files[0];
    setauthImageFile(file);
    const objectUrl = URL.createObjectURL(file);
    setAuthImagePreview(objectUrl);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = updatedData.coverImg;

    if (newImageFile) {
      const uploadedImageUrl = await uploadImageAndGetUrl();
      if (uploadedImageUrl) {
        imageUrl = uploadedImageUrl;
      }
    }

    let authImageUrl = updatedData.authorPic;

    if (authImageFile) {
      const uploadedAuthorImageUrl = await uploadAuthorImageAndGetUrl();
      if (uploadedAuthorImageUrl) {
        authImageUrl = uploadedAuthorImageUrl;// Set the uploaded URL to authorPic
      }
    }

    const finalUpdatedData = { ...updatedData, coverImg: imageUrl, newsId,authorPic: authImageUrl };

    try {
      const updateResponse = await allAPiServicesCall.putUpdateNews(
        finalUpdatedData,
        getAuthConfig()
      );

      if (updateResponse && updateResponse.success) {
        toast.success("News updated successfully");
        navigate("/news"); // Redirect to the news list page
      } else {
        toast.error("Failed to update news");
      }
    } catch (error) {
      console.error("Failed to update news:", error.response?.data || error);
      toast.error("An error occurred while updating the news");
    }
  };





  if (!newsDetails) return <div>Loading...</div>;

  return (
    <div className="edit-news-page container mt-4">
      <h2>Edit News</h2>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={updatedData.title}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Author</Form.Label>
          <Form.Control
            type="text"
            name="author"
            value={updatedData.author}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <JoditEditor
            ref={editor}
            name="description"
            value={updatedData.description}
            onChange={(newContent) =>
              setUpdatedData((prevData) => ({
                ...prevData,
                description: newContent,
              }))
            }
          />
        </Form.Group>

        {/* Additional Fields */}
        <Form.Group className="mb-3">
          <Form.Label>Read Time</Form.Label>
          <Form.Control
            type="text"
            name="readTime"
            value={updatedData.readTime}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Instagram</Form.Label>
          <Form.Control
            type="text"
            name="instagram"
            value={updatedData.instagram}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Author Picture</Form.Label>
          {updatedData.authorPic && (
            <div className="mb-3 text-center">
              <img
                src={authimagePreview || updatedData.authorPic}
                alt="Author"
                style={{ height: "200px", objectFit: "cover" }}
              />
            </div>
          )}
          <Form.Control
            type="file"
            name="authorPic"
            onChange={handleAuthorFileChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Author Designation</Form.Label>
          <Form.Control
            type="text"
            name="authorDesignation"
            value={updatedData.authorDesignation}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>News Image</Form.Label>
          {updatedData.coverImg && (
            <div className="mb-3 text-center ">
              <img
                src={imagePreview || updatedData.coverImg}
                alt="News"
                style={{ height: "200px", objectFit: "cover" }}
              />
            </div>
          )}
          <Form.Control
            type="file"
            name="coverImg"
            onChange={handleFileChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Publish Date</Form.Label>
          <Form.Control
            type="date"
            name="publishDate"
            value={updatedData.publishDate}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="float-end">
          Save Changes
        </Button>
        <Button variant="secondary" onClick={() => navigate("/news")}>
          Cancel
        </Button>
      </Form>
    </div>
  );
}

export default EditNewsPage;
