import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { allAPiServicesCall } from "../../services/apiServices";
import { getAuthConfig } from "../../services/apiUtils";

const Topbar = ({ onToggleSidebar }) => {
  const [adminData, setAdminData] = useState({});
  const [songs, setSongs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const navigate = useNavigate();

  const getDetails = () => {
    const details = JSON.parse(localStorage.getItem("admin-details"));
    setAdminData(details);
  };

  const handleLogout = () => {
    localStorage.removeItem("admin-details");
    localStorage.removeItem("auth-token");
    setAdminData({});
    navigate("/login");
  };

  const handleSearch = async (term) => {
    if (!term) return setSongs([]); // Clear results if search term is empty
    try {
      const response = await allAPiServicesCall.getSearchSong(
        { song_name: term },
        getAuthConfig()
      );
      if (response.success) {
        setSongs(response.data);
      } else {
        setSongs([]);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (debounceTimeout) clearTimeout(debounceTimeout); // Clear previous timeout
    setDebounceTimeout(setTimeout(() => handleSearch(term), 300)); // Set new timeout
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <nav
      className="navbar navbar-light sticky-top  w-100 "
      style={{ backgroundColor: "#ececec" ,position:"fixed"  }}
    >
      <div className="container-fluid d-flex align-items-center position-relative ">
        {/* Sidebar Toggle Icon */}
        <div onClick={onToggleSidebar} style={{ cursor: "pointer" }}>
          <FaBars />
        </div>

        {/* Centered Search Bar */}
        <div className="mx-auto" style={{ width: "50%", position: "relative" }}>
          <div className="input-group">
            <input
              type="search"
              className="form-control"
              placeholder="Search for a song..."
              aria-label="Search"
              value={searchTerm}
              onChange={handleInputChange} // Debounced input change handler
            />
          </div>

          {/* Display search results below the search bar */}
          {songs.length > 0 && (
            <ul
              className="list-group position-absolute"
              style={{
                top: "100%",
                zIndex: 1000,
                width: "100%",
                maxHeight: "300px",
                overflowY: "auto",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
              }}
            >
              {songs.map((song) => (
                <li
                  key={song._id}
                  className="list-group-item d-flex align-items-center"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                >
                  <img
                    src={song?.artist?.artist_image_link}
                    alt={song.song_name}
                    width="50"
                    height="50"
                    className="me-3"
                  />
                  <div>
                    <h6>{song?.song_name}</h6>
                    <p className="mb-0">Artist: {song?.artist?.artist_name}</p>
                    <p className="mb-0">Album: {song?.album?.album_name}</p>
                    <p className="mb-0">Genre: {song.genre}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* User Dropdown */}
        <div>
          <Dropdown align="end">
            <Dropdown.Toggle
              as="div"
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <img
                src={adminData?.avatar || "/default-avatar.png"}
                alt="User"
                className="rounded-circle"
                width="40"
                height="40"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate("/profile")}>
                View Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/change-password")}>
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
