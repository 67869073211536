import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container, Row, Col, Image, Spinner } from "react-bootstrap";
import CommonLayout from "../../../layouts/CommonLayout";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthConfig } from "../../../services/apiUtils";
import { allAPiServicesCall } from "../../../services/apiServices";


const ArtistAlbumsAndsongs = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const token = getAuthConfig()
    const { artistId } = useParams("artistId");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const payload = { artist_id: artistId };
                const response = await allAPiServicesCall.getArtistSongAndAlbums(getAuthConfig(), payload)
                if (response.status == 200 && response.success === true) {
                    setData(response.data[0]);
                    setLoading(false);
                } else {
                    setData([])
                    setLoading(false);
                }
            } catch (err) {
                setError("Failed to fetch data");
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (error) {
        return <div className="text-center text-danger mt-5">{error}</div>;
    }

    return (
        <CommonLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : error ? (
                <div className="text-center text-danger mt-5">{error}</div>
            ) : (
                <Container className="my-5 py-3">
                    {/* Artist Details */}
                    <h4 className="mb-3 text-center">Artist Details</h4>
                    <Card className="mb-4">
                        <Card.Body className="text-center">
                            <Image
                                src={data.artist_image_link}
                                roundedCircle
                                width={150}
                                height={150}
                                className="mb-3"
                            />
                            <Card.Title>{data.artist_name}</Card.Title>
                        </Card.Body>
                    </Card>

                    {/* Albums */}
                    <hr />
                    <h3 className="mb-3 text-center">ALBUMS</h3>
                    <Row className="mb-4">
                        {data?.albums?.map((album) => (
                            <Col key={album.album_id} sm={6} md={4} lg={3} className="mb-3">
                                <Card
                                    onClick={() => navigate(`/album/songs/${album.album_id}`)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Card.Img variant="top" src={album.image_link} />
                                    <Card.Body>
                                        <Card.Title className="text-truncate">
                                            {album.album_name}
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {/* Songs */}
                    <hr />
                    <h3 className="mb-3 text-center my-4">SONGS</h3>
                    <Row>
                        {data?.songs?.map((song) => (
                            <Col key={song.song_id} sm={6} md={4} lg={3} className="mb-3">
                                <Card
                                    onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Card.Img
                                        style={{ width: "100%", maxHeight: "200px" }}
                                        variant="top"
                                        src={
                                            song?.song_image_link !== null
                                                ? song?.song_image_link
                                                : "https://via.placeholder.com/150"
                                        }
                                    />
                                    <Card.Body>
                                        <Card.Title className="text-truncate">
                                            {song.song_name}
                                        </Card.Title>
                                        <Card.Text>
                                            <strong>Genre:</strong> {song.genre || "N/A"}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
        </CommonLayout>
    );

};

export default ArtistAlbumsAndsongs;
