import React, { useState, useEffect } from "react";
import CommonLayout from "../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../services/apiServices";
import { getAuthConfig } from "../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTE_CONSTANT } from "../../routes/constant";

function Dashboard() {
  const [trendingArtists, setTrendingArtists] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [albums, setAlbums] = useState([]);
  const navigate = useNavigate();

  const fetchTrendingArtists = async (page = 1, limit = 10) => {
    setLoading(true);

    try {
      let response = await allAPiServicesCall.getTrendingArtists(
        { limit, page },
        getAuthConfig()
      );

      const { totalPages, trendingList } = response.data;

      if (trendingList) {
        setTrendingArtists(trendingList);
        setTotalPages(totalPages);
      } else {
        console.warn("No data found or data is not an array");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching trending artists:", error);
      toast.error("Failed to fetch trending artists");
    }
  };

  const getRecommendedArtists = async () => {
    setLoading(true);

    try {
      let response = await allAPiServicesCall.getRecommendedArtists(
        {},
        getAuthConfig()
      );

      const { recommendedList } = response.data;

      if (recommendedList) {
        setSearchResults(recommendedList);
      } else {
        console.warn("No data found or data is not an array");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching recommended artists:", error);
    }
  };

  const fetchTopExploreAlbums = async () => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getTopExploreAlbums(getAuthConfig());

      if (response && response?.success) {
        setAlbums(response?.data?.albums);
      } else {
        toast.warn("No data found or invalid response.");
      }
    } catch (error) {
      toast.error("Failed to fetch top explore albums.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrendingArtists();
    getRecommendedArtists();
    fetchTopExploreAlbums();
  }, []);

  const handleViewMoreTrend = () => {
    navigate(ROUTE_CONSTANT.COMMON.TRENDING_ARTIST);
  };

  const handleViewMoreRec = () => {
    navigate(ROUTE_CONSTANT.COMMON.RECOMMENDED_ARTIST);
  };

  const handleViewMoreTrendTop = () => {
    navigate(ROUTE_CONSTANT.COMMON.TOP_EXPLORE);
  };

  return (
    <CommonLayout>
      <div style={{ marginBottom: "5rem",marginTop:"4rem" }}>
        <h3 className="section-title">TRENDING ARTISTS</h3>

        {loading ? (
          <p style={{ textAlign: "center" }}>Loading...</p>
        ) : (
          <div className="artist-section">
            <div className="artist-cards">
              {trendingArtists.slice(0, 4).map((artist, index) => (
                <div key={index} className="artist-card">
                  <img
                    src={artist.artist_image_link}
                    alt={artist.artist_name}
                    className="artist-image"
                  />
                  <h4 className="artist-name">{artist.artist_name}</h4>
                </div>
              ))}
            </div>
            <button className="view-more-btn" onClick={handleViewMoreTrend}>
              ➤
            </button>
          </div>
        )}

        <h3 className="section-title" style={{ marginTop: "5%" }}>
          RECOMMENDED ARTISTS
        </h3>

        {loading ? (
          <p style={{ textAlign: "center" }}>Loading...</p>
        ) : (
          <div className="artist-section">
            <div className="artist-cards">
              {searchResults.slice(0, 4).map((artist, index) => (
                <div key={index} className="artist-card">
                  <img
                    src={artist.artist_image_link}
                    alt={artist.artist_name}
                    className="artist-image"
                  />
                  <h4 className="artist-name">{artist.artist_name}</h4>
                </div>
              ))}
            </div>
            <button className="view-more-btn" onClick={handleViewMoreRec}>
              ➤
            </button>
          </div>
        )}

        <h3 className="section-title" style={{ marginTop: "5%" }}>
          TOP EXPLORE ALBUMS
        </h3>

        {loading ? (
          <p style={{ textAlign: "center" }}>Loading...</p>
        ) : (
          <div className="artist-section">
            <div className="artist-cards">
              {albums.slice(0, 4).map((album, index) => (
                <div key={index} className="artist-card">
                  <img
                    src={album.image_link}
                    alt={album.album_name}
                    className="artist-image"
                  />
                  <h4 className="artist-name">{album.album_name}</h4>
                </div>
              ))}
            </div>
            <button className="view-more-btn" onClick={handleViewMoreTrendTop}>
              ➤
            </button>
          </div>
        )}
      </div>

      <style jsx="true">{`
        .section-title {
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-size: 2.8rem;
          font-weight: 600;
          letter-spacing: 1.5px;
          text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
          margin-bottom: 1%;
          background: -webkit-linear-gradient(to right, #4c6ef5, #3a86ff);
          -webkit-background-clip: text;
          // margin-top: 5%;
        }

        .artist-section {
          display: flex;
          justify-content: center;
          position: relative;
          flex-wrap: wrap;
          gap: 20px;
        }

        .artist-cards {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          justify-content: center;
        }

        .artist-card {
          width: 250px;
          height: 250px;
          border: 1px solid #ddd;
          padding: 15px;
          border-radius: 12px;
          text-align: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          transition: transform 0.2s;
        }

        .artist-card:hover {
          transform: scale(1.05);
        }

        .artist-image {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 10px;
        }

        .artist-name {
          font-size: 18px;
          font-weight: bold;
          margin: 10px 0;
        }
.view-more-btn {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: black;
  font-size: 1.5rem; /* Use rem for scalability */
  cursor: pointer;
  padding: 0.5em; /* Provide some padding for touch targets */
  transition: transform 0.3s ease; /* Add transition for hover effects */
}

@media (max-width: 768px) { /* Tablet and smaller screens */
  .view-more-btn {
    font-size: 1.2rem;
    right: 2%;
  }
}

@media (max-width: 480px) { /* Mobile screens */
  .view-more-btn {
    font-size: 1rem;
    right: 1%;
    padding: 0.4em;
  }
}


        /* Media Queries for Responsiveness */
        @media (max-width: 1024px) {
          .artist-card {
            width: 200px;
            height: 200px;
          }

          .artist-image {
            width: 120px;
            height: 120px;
          }

          .artist-name {
            font-size: 16px;
          }
        }

        @media (max-width: 768px) {
          .artist-card {
            width: 180px;
            height: 180px;
          }

          .artist-image {
            width: 100px;
            height: 100px;
          }

          .artist-name {
            font-size: 14px;
          }

          .section-title {
            font-size: 2rem;
          }
        }

        @media (max-width: 480px) {
          .artist-section {
            gap: 10px;
          }

          .artist-card {
            width: 150px;
            height: 150px;
          }

          .artist-image {
            width: 80px;
            height: 80px;
          }

          .artist-name {
            font-size: 12px;
          }

          .section-title {
            font-size: 1.8rem;
          }
        }
      `}</style>
    </CommonLayout>
  );
}

export default Dashboard;
