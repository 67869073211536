export const ROUTE_CONSTANT = {
  COMMON: {
    HOME: "/",
    NEWS: "/news",
    NEWS_DETAILS: "/news-details/:newsId",
    TESTIMONIALS: "/testimonials",
    ADD_TESTIMONIAL: "/add_testimonial",
    FEEDBACKS: "/feedbacks",
    TRENDING_ARTIST: "/trending-artist",
    RECOMMENDED_ARTIST: "/recommended-artist",
    ADD_NEWS: "/add-news",
    SONG_LYRICS: "/lyrics/:isrcKey",
    ADD_HOT_SONGS: "/add-songs",
    ADD_HOT_ALBUM: "/add-hot-album",
    ALBUMS_SONGS: "/album/songs/:albumId",
    ARTIST_SONGS: "/artist/albums/:artistid",
    ADD_ARTIST: "/add-artist",
    RESET_PASSWORD: "/auth/reset-password/:token",
    SHOW_TESTIMONIAL: "/showtestimonial",
    TESTIMONIAL: "/testimonial",
    TOP_EXPLORE: "/top-explore",
    PLAYLIST: "/playlists",
    PLAYLIST_DETAILS: "/playlist/:playlistID",
    DASHBOARD: "/",
    RECOMMENDED_SONGS: "/recommended_songs",
    POPULAR_SONGS: "/popular_songs",
    CREATE_PLAYLIST: "/create_playlist",
    MOST_PLAYED_SONG: "/most-played-song",
    QUIZ: "/quiz",
    ADD_QUIZ: "/add-quiz",
    CONTACT_US: "/contact-us",
    ARTIST_ALBUMS_SONGS: "/artist/albums-songs/:artistId",
    TOP_ARTIST: "/top-artist",
    TOP_SONGS: "/top-songs",
    CONTRIBUTE_TO_LYRICS: "contribute-to-lyrics",
    ARTIST_BIO: "/artist-bio",
    EDIT_ARTIST_BIO: "/edit-artist-bio",
    EDIT_NEWS: "/edit-news/:newsId",
    SUBSCRIBER: "/news-subscriber",
    YOUTUBE_VIDEOS: "/youtube-videos",
    BANNER_SECTION: "/banner-section"
  },
  AUTH: {
    LOGIN: "/login",
    SHOW_PROFILE: "/profile",
    EDIT_PROFILE: "/edit-profile",
    CHANGE_PASSWORD: "/change-password",
    FORGET_PASSWORD: "/forget-password",
    RESET_PASSWORD: "/auth/reset-password/:resetToken",
    DEVELOPMENT: "/development",
    DEV_LOGIN: "/dev/login",
    DEV_FORGET: "/dev/forget",
    DEV_RESET: "/dev/reset",
    DEV_CHANGE: "/dev/change",
    DEV_SHOW: "/dev/show",
    DEV_EDIT: "/dev/edit",
  },
};
