// import React, { useState } from "react";
// import { Modal, Form, Button } from "react-bootstrap";
// import { allAPiServicesCall } from "../../services/apiServices";
// import { getAuthConfig, getFormDataConfig } from "../../services/apiUtils";
// import { toast } from "react-toastify";

// function EditPlaylistModal({ show, handleClose, playlistDetails, handleFormSubmit }) {
//   // State to manage form data
//   const [updatedData, setUpdatedData] = useState({
//     playlist_id: playlistDetails._id,
//     playlist_name: playlistDetails.playlist_name,
//     playlist_description: playlistDetails.playlist_description,
//     playlist_type: playlistDetails.playlist_type,
//     playlist_image: playlistDetails.playlist_image, // Initial image
//   });

//   // Separate state for the new file
//   const [newImageFile, setNewImageFile] = useState(null);

//   // Handle input changes for text fields
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setUpdatedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Handle file selection
//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setNewImageFile(file);
//   };

//   // Function to upload image and update URL
//   const uploadImageAndGetUrl = async () => {
//     if (!newImageFile) return null;
//     try {
//       const formData = new FormData();
//       formData.append("image", newImageFile);

//       // Call API to upload image
//       const response = await allAPiServicesCall.uploadPlaylistImage(
//         formData,
//         getFormDataConfig()
//       );
//       return response.data.path; // return image URL
//     } catch (error) {
//       console.error("Image upload failed:", error);
//       return null;
//     }
//   };

//   // Submit form and handle image upload if necessary
//   const onSubmit = async (e) => {
//     e.preventDefault();
//     let imageUrl = updatedData.playlist_image; // current image URL

//     // If there's a new file selected, upload it and get the new URL
//     if (newImageFile) {
//       const uploadedImageUrl = await uploadImageAndGetUrl();
//       if (uploadedImageUrl) {
//         imageUrl = uploadedImageUrl; // update the image URL
//       }
//     }

//     // Update `updatedData` state with the new or existing image URL
//     const finalUpdatedData = { ...updatedData, playlist_image: imageUrl };
//     setUpdatedData(finalUpdatedData);

//     try {
//       // Call update API with final updated data
//       const updateResponse = await allAPiServicesCall.patchUpdateAdminPlaylist(
//         finalUpdatedData,
//         getAuthConfig()
//       );

//       if (updateResponse && updateResponse.success) {
//         console.log("Playlist updated", updateResponse);
//         toast.success("Playlist updated successfully");
//         handleClose();
//       } else {
//         toast.error("Failed to update playlist");
//       }
//     } catch (error) {
//       console.error("Failed to update playlist:", error);
//       toast.error("An error occurred while updating the playlist");
//     }
//   };

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Edit Playlist</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form onSubmit={onSubmit}>
//           <Form.Group className="mb-3">
//             <Form.Label>Playlist Name</Form.Label>
//             <Form.Control
//               type="text"
//               name="playlist_name"
//               value={updatedData.playlist_name}
//               onChange={handleInputChange}
//               required
//             />
//           </Form.Group>

//           <Form.Group className="mb-3">
//             <Form.Label>Description</Form.Label>
//             <Form.Control
//               as="textarea"
//               name="playlist_description"
//               value={updatedData.playlist_description}
//               onChange={handleInputChange}
//             />
//           </Form.Group>

//           <Form.Group className="mb-3">
//             <Form.Label>Playlist Image</Form.Label>
//             <div className="mb-3">
//               <img
//                 src={updatedData.playlist_image}
//                 alt="Playlist"
//                 style={{ width: "100%", height: "auto", marginBottom: "10px" }}
//               />
//             </div>
//             <Form.Control
//               type="file"
//               name="playlist_image"
//               onChange={handleFileChange}
//             />
//           </Form.Group>

//           <Form.Group className="mb-3">
//             <Form.Label>Playlist Type</Form.Label>
//             <Form.Control
//               as="select"
//               name="playlist_type"
//               value={updatedData.playlist_type}
//               onChange={handleInputChange}
//             >
//               <option value="public">Public</option>
//               <option value="private">Private</option>
//             </Form.Control>
//           </Form.Group>

//           <Button variant="primary" type="submit">
//             Save Changes
//           </Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default EditPlaylistModal;


import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { allAPiServicesCall } from "../../services/apiServices";
import { getAuthConfig, getFormDataConfig } from "../../services/apiUtils";
import { toast } from "react-toastify";

function EditPlaylistModal({ show, handleClose, playlistDetails, handleFormSubmit, }) {
  // console.log(playlistDetails, "details")

  const [updatedData, setUpdatedData] = useState({
    playlist_id: playlistDetails._id,
    playlist_name: playlistDetails.playlist_name,
    playlist_description: playlistDetails.playlist_description,
    playlist_type: playlistDetails.playlist_type,
    playlist_image: playlistDetails.playlist_image,
  });

  useEffect(() => {
    if (playlistDetails) {
      setUpdatedData({ ...playlistDetails });
    }
  }, [playlistDetails]);

  const [newImageFile, setNewImageFile] = useState(null);
  const [imagePreview,setImagePreview] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewImageFile(file);
    const objectUrl = URL.createObjectURL(file);
    setImagePreview(objectUrl)
    

  };

  const uploadImageAndGetUrl = async () => {
    if (!newImageFile) return null;
    try {
      const formData = new FormData();
      formData.append("image", newImageFile);

      const response = await allAPiServicesCall.uploadPlaylistImage(
        formData,
        getFormDataConfig()
      );
      return response.data.path;
    } catch (error) {
      console.error("Image upload failed:", error.response?.data || error);
      return null;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = updatedData.playlist_image;

    if (newImageFile) {
      const uploadedImageUrl = await uploadImageAndGetUrl();
      if (uploadedImageUrl) {
        imageUrl = uploadedImageUrl;
      }
    }



    const finalUpdatedData = { ...updatedData, playlist_image: imageUrl, };
 
    const updatedataplaylistData = {
      ...finalUpdatedData,
      playlist_id: playlistDetails._id
    }
    // console.log(updatedataplaylistData, "updatedataplaylistData");
    try {
      const updateResponse = await allAPiServicesCall.patchUpdateAdminPlaylist(
        updatedataplaylistData,
        getAuthConfig()
      );
      console.log(updateResponse,"updateresponse");

      if (updateResponse && updateResponse.success) {
        toast.success("Playlist updated successfully");
        handleClose();
      } else {
        toast.error("Failed to update playlist");
      }
    } catch (error) {
      console.error("Failed to update playlist:", error.response?.data || error);
      toast.error("An error occurred while updating the playlist");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Playlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Playlist Name</Form.Label>
            <Form.Control
              type="text"
              name="playlist_name"
              value={updatedData.playlist_name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="playlist_description"
              value={updatedData.playlist_description}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Playlist Image</Form.Label>
            {updatedData.playlist_image && (
              <div className="mb-3">
                <img
                  src={imagePreview ||  updatedData.playlist_image}
                  alt="Playlist"
                  style={{ width: "100%", height: "auto", marginBottom: "10px" }}
                />
              </div>
            )}
            <Form.Control
              type="file"
              name="playlist_image"
              onChange={handleFileChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Playlist Type</Form.Label>
            <Form.Control
              as="select"
              name="playlist_type"
              value={updatedData.playlist_type}
              onChange={handleInputChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </Form.Control>
          </Form.Group>
         
          <Button variant="primary" type="submit" className="float-end">
            Save Changes
          </Button>
          <Button variant="danger" onClick={handleClose}>
                    Cancel
                </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditPlaylistModal;

