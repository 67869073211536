import React, { useState, useEffect } from "react";
import { Form, Button, Container, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig, getFormDataConfig } from "../../../services/apiUtils";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../../routes/constant";


const AddPlaylist = () => {
  const [playlistData, setPlaylistData] = useState({
    playlist_name: "",
    playlist_description: "",
    playlist_type: "Public",
    playlist_genre: ""
  });

  const [playlist_image, setPlaylistImage] = useState(null);
  const [genres, setGenres] = useState([]);
  const navigate = useNavigate();

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const response = await allAPiServicesCall.getGenres(getAuthConfig());
        if (response && response.success) {
          setGenres(response.data);
        } else {
          toast.error("Failed to fetch genres.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching genres.");
        console.error("Error fetching genres:", error);
      }
    };

    fetchGenres();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlaylistData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPlaylistImage(file);
    }
  };

  const handleGenreSelect = (genreId) => {
    const selectedGenre = genres.find(genre => genre._id === genreId);
    if (selectedGenre) {
      setPlaylistData((prevData) => ({
        ...prevData,
        playlist_genre: selectedGenre.genreName,
      }));
    }
  };

  const validateForm = () => {
    if (!playlistData.playlist_name || !playlistData.playlist_description) {
      toast.error("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const compressedImage = await imageCompression(playlist_image, options);
      const formData = new FormData();
      formData.append("image", compressedImage);

      const imageResponse = await allAPiServicesCall.uploadPlaylistImage(
        formData,
        getFormDataConfig()
      );

      const playlistDataPayload = {
        ...playlistData,
        playlist_image: imageResponse.data.path,
      };

      const response = await allAPiServicesCall.createAdminPlaylist(
        playlistDataPayload,
        getAuthConfig()
      );

      if (response && response.success) {
        toast.success("Playlist created successfully!");
        resetForm();
        navigate(ROUTE_CONSTANT.COMMON.PLAYLIST)

      }
    } catch (error) {
      toast.error("An error occurred while creating the playlist.");
      console.error("Error creating playlist:", error);
    }
  };

  const resetForm = () => {
    setPlaylistData({
      playlist_name: "",
      playlist_description: "",
      playlist_type: "Public",
      playlist_genre: ""
    });
    setPlaylistImage(null);
  };

  return (
    <CommonLayout>
      <Container className="mt-2 mb-5 py-3">
        <Card className="shadow-lg p-4" style={{ borderRadius: "10px", width: "100%" }}>
          <h2 className="text-center mb-4">Create a New Playlist</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="playlistName" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter playlist name"
                name="playlist_name"
                value={playlistData.playlist_name}
                onChange={handleChange}
                required
                className="p-2"
              />
            </Form.Group>

            <Form.Group controlId="playlistDescription" className="mb-3">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter playlist description"
                name="playlist_description"
                value={playlistData.playlist_description}
                onChange={handleChange}
                required
                className="p-2"
              />
            </Form.Group>

            <Form.Group controlId="playlistType" className="mb-3">
              <Form.Control
                as="select"
                name="playlist_type"
                value={playlistData.playlist_type}
                onChange={handleChange}
                className="p-2"
              >
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="playlistGenre" className="mb-3">
              <Form.Label>Playlist Genre</Form.Label>
              <Form.Control
                as="select"
                name="playlist_genre"
                value={playlistData.playlist_genre}
                onChange={handleChange}
                className="p-2"
              >
                <option value="">Select Genre</option>
                {genres.map((genre) => (
                  <option key={genre._id} value={genre._id}>
                    {genre.genreName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="playlistImage" className="mb-3">
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="p-2"
              />
              {playlist_image && (
                <div className="mt-3 text-center">
                  <img
                    src={URL.createObjectURL(playlist_image)}
                    alt="Selected playlist"
                    className="img-thumbnail"
                    style={{ maxWidth: "200px" }}
                  />
                  <p className="mt-2">
                    <strong>Selected Image:</strong> {playlist_image.name}
                  </p>
                </div>
              )}
            </Form.Group>

            <div className="text-center mt-4">
              <Button
                variant="primary"
                type="submit"
                className="px-5 py-2"
                style={{ borderRadius: "50px" }}
              >
                Create Playlist
              </Button>
            </div>
          </Form>
        </Card>
      </Container>
    </CommonLayout>
  );
};

export default AddPlaylist;
