import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Pagination } from "react-bootstrap";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import CommonLayout from "../../../layouts/CommonLayout";

const NewsSubsriber = () => {
  const [contactUs, setContactUs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPages, setCurrentPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);


  const getContactUs = async (pages) => {
    try {
      const response = await allAPiServicesCall.getNewsSubscriber(
        getAuthConfig(),
        pages
      );
      setContactUs(response.data.subscribers);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalSubscribers);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContactUs(currentPages);
  }, [currentPages]);

  const handlePageChange = (pageNumber) => {
    setCurrentPages(pageNumber);
  };

  return (
    <CommonLayout>
      <div
        className=" container"
        style={{
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <div className="text-center mt-5">
          <h3
            style={{
              textAlign: "center",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "2.8rem",
              fontWeight: "600",
              letterSpacing: "1.5px",
              textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
              marginBottom: "5%",
              background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
              WebkitBackgroundClip: "text",
            }}
          >
            News Subscribers
          </h3>
        </div>

        {loading ? (
          <div style={{ textAlign: "center" }}>
            <div className="spinner"></div>
          </div>
        ) : contactUs?.length === 0 ? (
          <div className="text-center my-5 text-muted">No Contact Us Found</div>
        ) : (
          <>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {contactUs?.map((contact, index) => (
                  <tr key={index}>
                   <td>{(currentPages - 1) * 10 + index + 1}</td> 
                    <td>{contact?.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPages - 1)}
                  disabled={currentPages === 1}
                />
                {[...Array(totalPages).keys()].map((number) => (
                  <Pagination.Item
                    key={number + 1}
                    active={number + 1 === currentPages}
                    onClick={() => handlePageChange(number + 1)}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPages + 1)}
                  disabled={currentPages === totalPages}
                />
              </Pagination>
            </div>
          </>
        )}
      </div>
    </CommonLayout>
  );
};

export default NewsSubsriber;
