import React, { useEffect, useState } from "react";
import CommonLayout from "../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../services/apiServices";
import { getAuthConfig } from "../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import { InputGroup, Form, CardFooter, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import "./MostPlayedSong.css";

const MostPlayedSong = () => {
  const navigate = useNavigate();
  const [mostPlayedSongs, setMostPlayedSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [deletingId, setDeletingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchMostPlayedList = async (page = 1) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getMostPlayedSong(
        { page, limit: 10 },
        getAuthConfig()
      );
      const { success, data } = response;

      if (success && Array.isArray(data.mostPlayedSongs)) {
        setMostPlayedSongs(data.mostPlayedSongs);
        setTotalPages(response.data.totalPages);
      } else {
        console.warn("No data found or data is not an array");
      }
    } catch (error) {
      console.error("Error fetching recommended songs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchSong = async (song_name) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getSearchSong(
        { song_name },
        getAuthConfig()
      );
      const searchList = response.data || [];
      if (searchList.length > 0) {
        setSearchResults(searchList);
      } else {
        setSearchResults([]);
        toast.info("No results found");
      }
    } catch (error) {
      console.error("Error fetching search song:", error);
      toast.error("Failed to fetch search song");
    } finally {
      setLoading(false);
    }
  };

  const addMostPlayedSong = async (song_id) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.addMostPlayedSong(
        { song_id },
        getAuthConfig()
      );
      if (response && response.success) {
        setSearchTerm("")
      } else {
        toast.error("Failed to add song.");
      }
    } catch (error) {
      toast.error("Failed to add song.");
    } finally {
      setLoading(false);
    }
  };

  const handleOnDelete = async () => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.deleteMostPlayedSong(
        { id: deletingId },
        getAuthConfig()
      );

      if (response && response.status === 200) {
        setShowDeleteModal(false);
        setMostPlayedSongs((prevSongs) =>
          prevSongs.filter((song) => song._id !== deletingId)
        );
      } else {
        toast.error("Failed to delete song!");
      }
    } catch (error) {
      toast.error("Failed to delete song!");
    } finally {
      setLoading(false);
      setDeletingId(null);
    }
  };

  const confirmDelete = (songId) => {
    setDeletingId(songId);
    setShowDeleteModal(true);
  };

  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim()) {
      fetchSearchSong(value);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      fetchMostPlayedList(currentPage);
    }
  }, [currentPage, searchTerm]);

  return (
    <CommonLayout>
      <div className="container mt-5" >
        <h3 className="pt-3" style={{
          textAlign: "center",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "2.8rem",
          fontWeight: "600",
          letterSpacing: "1.5px",
          textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
          marginBottom: "1%",
          background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
          WebkitBackgroundClip: "text",
        }}>Most Played Songs</h3>

        <InputGroup className="mb-3">
          <Form.Control
            className="search-input"
            placeholder="Search for a song..."
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </InputGroup>

        {loading ? (
          <div className="text-center">Loading...</div>
        ) : searchTerm && searchResults.length > 0 ? (
          <div className="card-container">
            {searchResults.map((song) => (
              <div key={song._id} className="card-image">
                <img
                  src={song.song_image_link || "/default-image.jpg"}
                  alt={song.song_name || "Unknown Song"}
                />
                <h3>{song.song_name}</h3>
                <h4>Artist: {song.artist?.artist_name || "Unknown Artist"}</h4>
                <p>Album: {song.album?.album_name || "Unknown Album"}</p>
                <CardFooter>
                  <Button
                    className="add-button"
                    onClick={() => addMostPlayedSong(song.song_id)}
                  >
                    Add to Most Played
                  </Button>
                </CardFooter>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-container">
            {mostPlayedSongs.length > 0 ? (
              mostPlayedSongs.map((song) => (
                <div key={song._id} className="card-image">
                  <img
                    src={song.song_image_link || "/default-image.jpg"}
                    alt={song.song_name || "Unknown Song"}
                  />
                  <h3>{song.song_name}</h3>
                  <h4>Artist : {song.artist?.artist_name || "Unknown Artist"}</h4>
                  <p>Album : <ReadMoreText text={song.album?.album_name || "Unknown Album"} /></p>

                  <CardFooter>
                    <Button
                      className="show-button"
                      onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                    >
                      See Lyrics
                    </Button>
                    <Button
                      className="delete-button"
                      onClick={() => confirmDelete(song._id)}
                    >
                      Delete
                    </Button>
                  </CardFooter>
                </div>
              ))
            ) : (
              <p>No songs available.</p>
            )}
          </div>
        )}
        <div className="pagination">

          {currentPage > 1 && (
            <button onClick={() => setCurrentPage(currentPage - 1)}>
              Previous
            </button>
          )}
          {currentPage < totalPages && (
            <button onClick={() => setCurrentPage(currentPage + 1)}>
              Next
            </button>
          )}
        </div>

        {mostPlayedSongs.length > 0 && (
          <div className="pagination-container">
            <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            <span>
              {currentPage} / {totalPages}
            </span>
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this artist?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleOnDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
};


const ReadMoreText = ({ text, maxLength = 15, isTitle = false, minimumLenth = 11 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const truncatedText =
    text.length > maxLength && !isExpanded
      ? `${text.slice(minimumLenth, maxLength)}...`
      : text;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <span>
      {truncatedText}
      {text.length > maxLength && (
        <span
          style={{
            color: "blue",
            cursor: "pointer",
            marginLeft: "5px",
            fontSize: isTitle ? "0.85rem" : "0.8rem",
          }}
          onClick={toggleExpanded}
        >
          {isExpanded ? "read less" : "Read more"}
        </span>
      )}
    </span>
  );
};


export default MostPlayedSong;
