import React, { useState, useEffect } from "react";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrash, FaEye, FaEdit } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import CommonLayout from "../../../layouts/CommonLayout";
import debounce from "lodash/debounce"; // Install lodash for debounce functionality
import { toast } from "react-toastify";

function PlaylistDetail() {
  const { playlistID } = useParams();
  const [playlistDetails, setPlaylistDetails] = useState(null);
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSongId, setSelectedSongId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noSongsMessage, setNoSongsMessage] = useState(""); // State for no songs message
  const navigate = useNavigate();

  // Debounced search function to optimize API calls
  const debouncedSearch = debounce(async (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getSearchSong(
        { song_name: query },
        getAuthConfig()
      );
      if (response && response.success) {
        setSearchResults(response.data);
      } else {
        setNoSongsMessage("No results found!"); // Show message when no results found
      }
    } catch (error) {
      setNoSongsMessage("Failed to search songs"); // Show message when search fails
    } finally {
      setLoading(false);
    }
  }, 500); // Debounce delay of 500ms

  const fetchPlaylistSongs = async (playlistID) => {
    setLoading(true);
    setNoSongsMessage(""); // Reset no songs message on fetch
    try {
      const response = await allAPiServicesCall.getPlaylistSong(
        { playlist_id: playlistID },
        getAuthConfig()
      );
      if (response?.data?.song_data && response.data.song_data.length > 0) {
        // Filter out empty objects from song_data
        const filteredSongs = response.data.song_data.filter(song => Object.keys(song).length > 0);
        if (filteredSongs.length === 0) {
          setNoSongsMessage("No songs found in playlist"); // Set message if no valid songs
        } else {
          setPlaylistDetails(response.data);
          setPlaylistSongs(filteredSongs);
        }
      } else {
        setNoSongsMessage("No songs found in playlist"); // Set message when no songs in response
      }
    } catch (error) {
      setNoSongsMessage("Failed to fetch playlist songs"); // Set message on error
    } finally {
      setLoading(false);
    }
  };

  const handleOnRemove = async () => {
    if (!selectedSongId) return;
    setLoading(true);
    try {
      const response = await allAPiServicesCall.patchRemoveAdminPlaylistSong(
        { playlist_id: playlistID, song_id: selectedSongId },
        getAuthConfig()
      );
      if (response?.status === 200) {
        setPlaylistSongs((prev) =>
          prev.filter((song) => song.song_id !== selectedSongId)
        );
        toast.success("Song removed successfully");
        fetchPlaylistSongs(playlistID);
      } else {
        setNoSongsMessage("Failed to remove song");
      }
    } catch (error) {
      setNoSongsMessage("Failed to remove song");
    } finally {
      setLoading(false);
      setSelectedSongId(null);
      setShowDeleteModal(false);
    }
  };

  const handleDeleteClick = (song_id) => {
    setSelectedSongId(song_id);
    setShowDeleteModal(true);
  };

  const handleAddSong = async (song_id) => {
    setLoading(true);
    try {
      const payload = { playlist_id: playlistID, song_id };
      const response = await allAPiServicesCall.addSongToAdminPlayList(payload, getAuthConfig());
      if (response?.status === 201) {
        setSearchQuery("");
        setSearchResults([]);
        fetchPlaylistSongs(playlistID);
      } else {
        setNoSongsMessage("Failed to add song to playlist");
      }
    } catch (error) {
      setNoSongsMessage("Error adding song");
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (isrc) => {
    // Navigate to a page where the song details can be viewed
    navigate(`/lyrics/${isrc}`);
  };

  useEffect(() => {
    if (playlistID) fetchPlaylistSongs(playlistID);
  }, [playlistID]);

  return (
    <CommonLayout>
      <div style={styles.container}>
        <h1 style={styles.heading}>{playlistDetails?.playlist_name}</h1>
        <div style={styles.topBar}>
          <input
            type="text"
            placeholder="Search songs..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            style={styles.searchBar}
          />
        </div>

        {/* Show message if no songs or search results */}
        {noSongsMessage && <div style={styles.noSongsMessage}>{noSongsMessage}</div>}

        {/* Search Results */}
        {searchResults.length > 0 && (
          <div style={styles.searchResults}>
            {searchResults.map((song) => (
              <div key={song.song_id} style={styles.searchResultRow}>
                <div style={styles.songInfo}>
                  <img
                    src={song?.artist?.artist_image_link}
                    alt={song.song_name}
                    style={styles.image}
                  />
                  <span>{song.song_name}</span>
                </div>

                <p className="ms-auto"><span className="px-5">~{song?.artist.artist_name}</span></p>
                <Button
                  style={styles.addButton}
                  onClick={() => handleAddSong(song.song_id)}
                >
                  Add
                </Button>
              </div>
            ))}
          </div>
        )}

        {/* Only render the table if there are songs */}
        {playlistSongs.length > 0 ? (
          <table style={styles.table} className="table table-hover">
            <thead>
              <tr>
                <th style={styles.tableHeader}>Image</th>
                <th style={styles.tableHeader}>Song Name</th>
                <th style={styles.tableHeader}>Artist</th>
                <th style={styles.tableHeader}>Action</th>
              </tr>
            </thead>
            <tbody>
              {playlistSongs.map((song) => (
                <tr key={song.song_id}>
                  <td style={styles.tableHeader}>
                    <img
                      src={song.song_image_link || "/default-image.jpg"}
                      alt={song.song_name || "No Name"}
                      style={styles.image}
                    />
                  </td>
                  <td style={styles.tableHeader}>{song.song_name}</td>
                  <td style={styles.tableHeader}>{song.artist_name}</td>
                  <td style={styles.tableHeader}>
                    <FaEye
                      style={styles.viewIcon}
                      onClick={() => handleViewClick(song.isrc_code)}
                      title="View Lyrics"
                    />
                    <FaTrash
                      style={styles.deleteIcon}
                      onClick={() => handleDeleteClick(song.song_id)}
                      title="Delete Song"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this song?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleOnRemove}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </CommonLayout>
  );
}

const styles = {
  container: {
    margin: "20px auto 60px auto",
    width: "80%",
  },
  heading: {
    textAlign: "center",
    fontSize: "2rem",
    marginTop: "50px",
    marginBottom: "20px"
  },
  topBar: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  searchBar: {
    width: "50%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  searchResults: {
    margin: "20px 0",
  },
  searchResultRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 10px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  songInfo: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  addButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  tableHeader: {
    padding: "10px",
    textAlign: "center",
    border: "1px solid #ddd",
  },
  viewIcon: {
    cursor: "pointer",
    color: "blue",
    fontSize: "1.5rem",
    marginRight: "10px",
  },
  editIcon: {
    cursor: "pointer",
    color: "orange",
    fontSize: "1.5rem",
    marginRight: "10px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: "red",
    fontSize: "1.5rem",
  },

  noSongsMessage: {
    textAlign: "center",
    color: "red",
    fontSize: "1.2rem",
    margin: "20px 0",
  },
};

export default PlaylistDetail;
