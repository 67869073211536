import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import CommonLayout from "../../../layouts/CommonLayout";

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const getFeedbacks = async () => {
    try {
      const response = await allAPiServicesCall.getFeedback(
        {},
        getAuthConfig(),
        navigate
      );
      setFeedbacks(response.data);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewMore = (feedback) => {
    setSelectedFeedback(feedback);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    getFeedbacks();
  }, []);

  return (
    <CommonLayout>
      <div className="p-4">
        <div className="text-center my-4">
          <h3>User Feedbacks</h3>
        </div>

        {loading ? (
          <div style={{ textAlign: "center" }}>
            <div className="spinner"></div>
          </div>
        ) : feedbacks.length === 0 ? (
          <div className="text-center my-5 text-muted">No Feedbacks Found</div>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th style={{ width: "16%" }}>Name</th>
                <th style={{ width: "20%" }}>Email</th>
                <th style={{ width: "20%" }}>Subject</th>
                <th style={{ width: "20%" }}>Message</th>
                <th style={{ width: "10%" }}>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {feedbacks.map((feedback, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{feedback.name}</td>
                  <td>{feedback.email}</td>
                  <td>{feedback.subject}</td>
                  <td>
                    {feedback.message.length > 5
                      ? feedback.message.substring(0, 25) + "..."
                      : feedback.message}
                  </td>
                  <td>{new Date(feedback.createdAt).toLocaleDateString()}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{
                        margin: "0 8px",
                        padding: "6px 15px",
                      }}
                      onClick={() => handleViewMore(feedback)}
                    >
                      View More
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {/* Modal for displaying full details */}
        {selectedFeedback && (
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Feedback Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Name:</strong> {selectedFeedback.name}
              </p>
              <p>
                <strong>Email:</strong> {selectedFeedback.email}
              </p>
              <p>
                <strong>Subject:</strong> {selectedFeedback.subject}
              </p>
              <p>
                <strong>Message:</strong> {selectedFeedback.message}
              </p>
              <p>
                <strong>Date:</strong>{" "}
                {new Date(selectedFeedback.createdAt).toLocaleDateString()}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </CommonLayout>
  );
};

export default Feedback;
