import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, ListGroup, Spinner } from 'react-bootstrap';
import CommonLayout from '../../../layouts/CommonLayout';
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig } from '../../../services/apiUtils';

const AlbumsSongs = () => {
    const { albumId } = useParams();
    const [albumData, setAlbumData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchSongs = async () => {
        try {
            setLoading(true);
            const response = await allAPiServicesCall.albumSongs({ album_id: albumId }, getAuthConfig(), navigate);
            if (response && response.data) {
                setAlbumData(response.data[0]);
            } else {
                setAlbumData(null);
            }
        } catch (error) {
            console.error("Error fetching songs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSongs();
    }, [albumId]);

    const handleSongClick = (songId) => {
        navigate(`/lyrics/${songId}`);
    };

    return (
        <CommonLayout>
            <div className="container mt-5" style={{ marginBottom: "100px" }}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : albumData ? (
                    <>
                        <div className='d-flex justify-content-center '>
                            <Card className='mt-4'>
                                <Card.Img variant="top" src={albumData.image_link || '/assets/placeholder.jpg'} />
                                <Card.Body>
                                    <Card.Title>{albumData.album_name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{albumData.artist.artist_name}</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </div>
                        <ListGroup variant="flush">
                            {albumData.songs.map((song) => (
                                <ListGroup.Item
                                    key={song._id}
                                    action
                                    onClick={() => handleSongClick(song.isrc_code)}
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                >
                                    <img
                                        src={song.song_image_link}
                                        alt=""
                                        width="50"
                                        height="50"
                                        style={{
                                            borderRadius: '50%',
                                            marginRight: '15px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <div style={{ flex: 1 }}>
                                        <h5 className="mb-1" style={{ color: "#13274F" }}>{song.song_name}</h5>
                                    </div>
                                    <small style={{ color: "#6c757d" }}>{song.genre !== 'Not found' ? song.genre : "Unknown Genre"}</small>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </>
                ) : (
                    <p className='text-center'>No album data found.</p>
                )}
            </div>
        </CommonLayout>
    );
};

export default AlbumsSongs;
