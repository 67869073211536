// import React, { useState } from "react";
// import { MDBContainer, MDBInput } from "mdb-react-ui-kit";
// import CommonLayout from "../../../layouts/CommonLayout";
// import { allAPiServicesCall } from "../../../services/apiServices";
// import { getAuthConfig } from "../../../services/apiUtils";
// import { useNavigate } from "react-router-dom";
// import { Button } from "react-bootstrap";
// import { FaEye, FaEyeSlash } from "react-icons/fa";

// function ChangePassword() {
//   const [oldPassword, setOldPassword] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [showOldPassword, setShowOldPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const navigate = useNavigate();

//   const validatePassword = (password) => {
//     const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/;
//     return regex.test(password);
//   };

//   const handleSubmit = async () => {
//     if (newPassword !== confirmPassword) {
//       setError("New Password and Confirm Password do not match");
//       return;
//     }

//     if (!validatePassword(newPassword)) {
//       setError(
//         "Password must be at least 8 characters long, with at least one special character, one capital letter, one small letter, and one digit."
//       );
//       return;
//     }

//     try {
//       const payload = {
//         currentPassword: oldPassword,
//         newPassword: newPassword,
//       };
//       const response = await allAPiServicesCall.changePassword(
//         payload,
//         getAuthConfig(),
//         navigate
//       );
//       if (response.success) {
//         setSuccess(response.message);
//         setError("");
//       }
//     } catch (error) {
//       setError("Failed to change password. Please try again.");
//       setSuccess("");
//     }
//   };

//   return (
//     <CommonLayout>
//       <div
//         style={{
//           minHeight: "100vh",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <div
//           style={{
//             width: "550px",
//             backgroundColor: "#fff",
//             borderRadius: "10px",
//             boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
//             padding: "20px",
//           }}
//         >
//           <h1
//             className="text-center"
//             style={{ marginBottom: "20px", color: "#333" }}
//           >
//             Change Password
//           </h1>
//           {error && <p style={{ color: "red" }}>{error}</p>}
//           {success && <p style={{ color: "green" }}>{success}</p>}

//           <div className="position-relative mb-3">
//             <MDBInput
//               wrapperClass="mt-2"
//               placeholder="Old Password"
//               id="form1"
//               type={showOldPassword ? "text" : "password"}
//               value={oldPassword}
//               onChange={(e) => setOldPassword(e.target.value)}
//               style={inputStyle}
//             />
//             <span
//               onClick={() => setShowOldPassword(!showOldPassword)}
//               style={iconStyle}
//             >
//               {showOldPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>

//           <div className="position-relative mb-3">
//             <MDBInput
//               wrapperClass="mt-2"
//               placeholder="New Password"
//               id="form2"
//               type={showNewPassword ? "text" : "password"}
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//               style={inputStyle}
//             />
//             <span
//               onClick={() => setShowNewPassword(!showNewPassword)}
//               style={iconStyle}
//             >
//               {showNewPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>

//           <div className="position-relative mb-3">
//             <MDBInput
//               wrapperClass="mt-2"
//               placeholder="Confirm password"
//               id="form3"
//               type={showConfirmPassword ? "text" : "password"}
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               style={inputStyle}
//             />
//             <span
//               onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//               style={iconStyle}
//             >
//               {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//           </div>

//           <Button
//             className="my-4"
//             onClick={handleSubmit}
//             style={{
//               backgroundColor: "#007bff",
//               border: "none",
//               borderRadius: "5px",
//               padding: "10px 15px",
//               width: "20%",
//               marginLeft: "30vh",
//               transition: "background-color 0.3s ease, transform 0.3s ease",
//             }}
//             onMouseEnter={(e) => {
//               e.target.style.backgroundColor = "#0056b3";
//               e.target.style.transform = "scale(1.05)";
//             }}
//             onMouseLeave={(e) => {
//               e.target.style.backgroundColor = "#007bff";
//               e.target.style.transform = "scale(1)";
//             }}
//           >
//             Submit
//           </Button>
//         </div>
//       </div>
//     </CommonLayout>
//   );
// }

// const inputStyle = {
//   borderRadius: "5px",
//   border: "1px solid #ccc",
//   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   transition: "all 0.3s ease",
// };

// const iconStyle = {
//   color: "#007bff",
//   cursor: "pointer",
//   fontSize: "1.4em",
//   position: "absolute",
//   right: "10px",
//   top: "38%",
//   transform: "translateY(-50%)",
// };

// export default ChangePassword;


import React, { useState } from "react";
import { MDBContainer, MDBInput } from "mdb-react-ui-kit";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError("New Password and Confirm Password do not match");
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(
        "Password must be at least 8 characters long, with at least one special character, one capital letter, one small letter, and one digit."
      );
      return;
    }

    try {
      const payload = {
        currentPassword: oldPassword,
        newPassword: newPassword,
      };
      const response = await allAPiServicesCall.changePassword(
        payload,
        getAuthConfig(),
        navigate
      );
      if (response.success) {
        setSuccess(response.message);
        setError("");
      }
    } catch (error) {
      setError("Failed to change password. Please try again.");
      setSuccess("");
    }
  };

  return (
    <CommonLayout>
      <MDBContainer className="d-flex justify-content-center mt-5">
     

<div
  className="change-password-container p-4 mt-4"
  style={{
    position: 'relative',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Border shadow
    backdropFilter: 'blur(10px)', // Background blur effect
    background: 'rgba(255, 255, 255, 0.8)', // Add semi-transparent background for blur effect
    // overflow: 'hidden', // Ensure child elements don't overflow the blurred area
  }}
>
  <h1
    className="text-center mb-4"
    style={{
      textAlign: 'center',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '2.8rem',
      fontWeight: '600',
      letterSpacing: '1.5px',
      textShadow: '3px 3px 8px rgba(0, 0, 0, 0.3)',
      marginBottom: '5%',
      background: '-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)',
      WebkitBackgroundClip: 'text',
    }}
  >
    Change Password
  </h1>
  {error && <p style={{ color: 'red' }}>{error}</p>}
  {success && <p style={{ color: 'green' }}>{success}</p>}

  <div className="position-relative mb-3">
    <MDBInput
      wrapperClass="mt-2"
      placeholder="Old Password"
      id="form1"
      type={showOldPassword ? 'text' : 'password'}
      value={oldPassword}
      onChange={(e) => setOldPassword(e.target.value)}
      style={inputStyle}
        autoComplete="new-password"
  
    />
    <span onClick={() => setShowOldPassword(!showOldPassword)} style={iconStyle}>
      {showOldPassword ? <FaEye /> :  <FaEyeSlash />}
    </span>
  </div>

  <div className="position-relative mb-3">
    <MDBInput
      wrapperClass="mt-2"
      placeholder="New Password"
      id="form2"
      type={showNewPassword ? 'text' : 'password'}
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      style={inputStyle}
    />
    <span onClick={() => setShowNewPassword(!showNewPassword)} style={iconStyle}>
      {showNewPassword ? <FaEye /> : <FaEyeSlash />}
    </span>
  </div>

  <div className="position-relative mb-3">
    <MDBInput
      wrapperClass="mt-2"
      placeholder="Confirm Password"
      id="form3"
      type={showConfirmPassword ? 'text' : 'password'}
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      style={inputStyle}
    />
    <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={iconStyle}>
      {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
    </span>
  </div>

  <Button
    className="my-4 w-100"
    onClick={handleSubmit}
    style={buttonStyle}
    onMouseEnter={(e) => {
      e.target.style.backgroundColor = '#0056b3';
      e.target.style.transform = 'scale(1.05)';
    }}
    onMouseLeave={(e) => {
      e.target.style.backgroundColor = '#007bff';
      e.target.style.transform = 'scale(1)';
    }}
  >
    Submit
  </Button>
</div>

      </MDBContainer>
    </CommonLayout>
  );
}

const inputStyle = {
  borderRadius: "5px",
  border: "1px solid #ccc",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
};

const iconStyle = {
  color: "#007bff",
  cursor: "pointer",
  fontSize: "1.4em",
  position: "absolute",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)",
};

const buttonStyle = {
  backgroundColor: "#007bff",
  border: "none",
  borderRadius: "5px",
  padding: "10px 15px",
  transition: "background-color 0.3s ease, transform 0.3s ease",
};

export default ChangePassword;

