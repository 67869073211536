import { Route } from "react-router-dom";
import { ROUTE_CONSTANT } from "./constant";
import HomePage from "../pages/common/Home/HomePage";
import ProtectedRoute from "./protectedRoutes";
import NewsPage from "../pages/common/NewsPage/NewsPage";
import NewsDetails from "../pages/common/NewsPage/NewsDetails";
import Testimonials from "../pages/common/Testimonials/Testimonials";
import Feedback from "../pages/common/Feedback/Feedback";
import HotSongs from "../pages/common/HotSongs/HotSongs";
import TopChartPage from "../pages/common/TopChartPage/TopChartPage";
import AddNews from "../pages/common/NewsPage/AddNews";
import AddTestimonial from "../pages/common/Testimonials/AddTestimonial";
import DisplayLyrics from "../pages/common/Lyrics/DisplayLyrics";
import AddHotSong from "../pages/common/HotSongs/AddHotSong";
import AlbumsSongs from "../pages/common/AlbumsSongs/AlbumsSongs";
import ArtistSongs from "../pages/common/ArtistSongs/ArtistSongs";
import AddArtist from "../pages/common/AddArtist/AddArtist";
import AddHotAlbum from "../pages/common/HotAlbum/AddHotAlbum";
import ShowTestimonials from "../pages/common/Testimonials/ShowTestimonial";
import TrendingArtist from "../pages/common/TrendingArtist/TrendingArtist";
import RecommendedArtist from "../pages/common/RecArtist/RecommendedArtist";
import TopExplore from "../pages/common/TopExplore/TopExplore";
import Playlist from "../pages/common/Playlist/Playlist";
import PlaylistDetail from "../pages/common/PlaylistDetail/PlaylistDetail";
import Dashboard from "../components/Dashboard/Dashboard";
import RecommendedSongs from "../components/Songs/RecommendedSongs";
import PopularSongs from "../pages/common/PopularSongs/PopularSongs";
import MostPlayedSong from "../components/Songs/MostPlayedSong";
import AddPlaylist from "../pages/common/AddPlaylist/AddPlaylist";
import QuizList from "../pages/common/Quiz/QuizList";
import AddQuiz from "../pages/common/Quiz/AddQuiz";
import Contactus from "../pages/common/Contactus/Contactus";
import ArtistAlbumsAndsongs from "../pages/common/ArtistAlbums&Songs/ArtistAlbumsAndsongs";
import TopArtist from "../pages/common/TopArtist/TopArtist";
import TopSong from "../components/Songs/TopSong";
import ContributeToLyricsList from "../pages/common/ContributeToLyrics/ContributeToLyricsList";
import ArtistBio from "../pages/common/ArtistBIo/ArtistBio";
import EditArtistBio from "../pages/common/ArtistBIo/EditArtistBio";
import EditNews from "../pages/common/NewsPage/EditNews";
import NewsSubsriber from "../pages/common/NewsSubscriber/NewsSubscriber";
import YoutubeVideo from "../pages/common/YoutubeVideo/YoutubeVideo";
import BannerSection from "../pages/common/Banner/BannerSection";


const commonRoutes = [
  <Route
    key="home"
    path={ROUTE_CONSTANT.COMMON.HOME}
    element={<ProtectedRoute element={Dashboard} />}
  />,
  <Route
    key="news"
    path={ROUTE_CONSTANT.COMMON.NEWS}
    element={<ProtectedRoute element={NewsPage} />}
  />,
  <Route
    key="news-details"
    path={ROUTE_CONSTANT.COMMON.NEWS_DETAILS}
    element={<ProtectedRoute element={NewsDetails} />}
  />,
  <Route
    key="testimonials"
    path={ROUTE_CONSTANT.COMMON.TESTIMONIALS}
    element={<ProtectedRoute element={Testimonials} />}
  />,
  <Route
    key="feedbacks"
    path={ROUTE_CONSTANT.COMMON.FEEDBACKS}
    element={<ProtectedRoute element={Feedback} />}
  />,
  <Route
    key="hot-songs"
    path={ROUTE_CONSTANT.COMMON.HOT_SONGS}
    element={<ProtectedRoute element={HotSongs} />}
  />,
  <Route
    key="add-hot-songs"
    path={ROUTE_CONSTANT.COMMON.ADD_HOT_SONGS}
    element={<ProtectedRoute element={AddHotSong} />}
  />,
  // <Route
  //   key="add-hot-songs"
  //   path={ROUTE_CONSTANT.COMMON.ADD_HOT_ALBUM}
  //   element={<ProtectedRoute element={AddHotAlbum} />}
  // />,
  // <Route
  //   key="top-charts"
  //   path={ROUTE_CONSTANT.COMMON.TOP_CHARTS}
  //   element={<ProtectedRoute element={TopChartPage} />}
  // />,

  <Route
    key="trending-artist"
    path={ROUTE_CONSTANT.COMMON.TRENDING_ARTIST}
    element={<ProtectedRoute element={TrendingArtist} />}
  />,
  <Route
    key="recommended-artist"
    path={ROUTE_CONSTANT.COMMON.RECOMMENDED_ARTIST}
    element={<ProtectedRoute element={RecommendedArtist} />}
  />,

  <Route
    key="add-news"
    path={ROUTE_CONSTANT.COMMON.ADD_NEWS}
    element={<ProtectedRoute element={AddNews} />}
  />,
  <Route
    key="add-testimonial"
    path={ROUTE_CONSTANT.COMMON.ADD_TESTIMONIAL}
    element={<ProtectedRoute element={AddTestimonial} />}
  />,
  <Route
    key="song-lyrics"
    path={ROUTE_CONSTANT.COMMON.SONG_LYRICS}
    element={<ProtectedRoute element={DisplayLyrics} />}
  />,
  <Route
    key="albums-songs"
    path={ROUTE_CONSTANT.COMMON.ALBUMS_SONGS}
    element={<ProtectedRoute element={AlbumsSongs} />}
  />,
  <Route
    key="artist-songs"
    path={ROUTE_CONSTANT.COMMON.ARTIST_SONGS}
    element={<ProtectedRoute element={ArtistSongs} />}
  />,
  <Route
    key="add-artist"
    path={ROUTE_CONSTANT.COMMON.ADD_ARTIST}
    element={<ProtectedRoute element={AddArtist} />}
  />,
  <Route
    key="show-testimonial"
    path={ROUTE_CONSTANT.COMMON.SHOW_TESTIMONIAL}
    element={<ProtectedRoute element={ShowTestimonials} />}
  />,

  <Route
    key="top-explore"
    path={ROUTE_CONSTANT.COMMON.TOP_EXPLORE}
    element={<ProtectedRoute element={TopExplore} />}
  />,

  <Route
    key="playlist"
    path={ROUTE_CONSTANT.COMMON.PLAYLIST}
    element={<ProtectedRoute element={Playlist} />}
  />,

  <Route
    key="playlist"
    path={ROUTE_CONSTANT.COMMON.PLAYLIST_DETAILS}
    element={<ProtectedRoute element={PlaylistDetail} />}
  />,

  <Route
    key="dashboard"
    path={ROUTE_CONSTANT.COMMON.DASHBOARD}
    element={<ProtectedRoute element={Dashboard} />}
  />,

  <Route
    key="songs"
    path={ROUTE_CONSTANT.COMMON.RECOMMENDED_SONGS}
    element={<ProtectedRoute element={RecommendedSongs} />}
  />,
  <Route
    key="songs"
    path={ROUTE_CONSTANT.COMMON.POPULAR_SONGS}
    element={<ProtectedRoute element={PopularSongs} />}
  />,
  <Route
    key="songs"
    path={ROUTE_CONSTANT.COMMON.CREATE_PLAYLIST}
    element={<ProtectedRoute element={AddPlaylist} />}
  />,

  <Route
    key="mostPlayedSong"
    path={ROUTE_CONSTANT.COMMON.MOST_PLAYED_SONG}
    element={<ProtectedRoute element={MostPlayedSong} />}
  />,

  <Route
    key="quizlist"
    path={ROUTE_CONSTANT.COMMON.QUIZ}
    element={<ProtectedRoute element={QuizList} />}
  />,
  <Route
    key="quizlist"
    path={ROUTE_CONSTANT.COMMON.ADD_QUIZ}
    element={<ProtectedRoute element={AddQuiz} />}
  />,
  <Route
    key="quizlist"
    path={ROUTE_CONSTANT.COMMON.CONTACT_US}
    element={<ProtectedRoute element={Contactus} />}
  />,
  <Route
    key="artist-song-ablums"
    path={ROUTE_CONSTANT.COMMON.ARTIST_ALBUMS_SONGS}
    element={<ProtectedRoute element={ArtistAlbumsAndsongs} />}
  />,
  <Route
    key="top-artist"
    path={ROUTE_CONSTANT.COMMON.TOP_ARTIST}
    element={<ProtectedRoute element={TopArtist} />}
  />,
  <Route
    key="top-songs"
    path={ROUTE_CONSTANT.COMMON.TOP_SONGS}
    element={<ProtectedRoute element={TopSong} />}
  />,
  <Route
    key="contribute-to-lyrics"
    path={ROUTE_CONSTANT.COMMON.CONTRIBUTE_TO_LYRICS}
    element={<ProtectedRoute element={ContributeToLyricsList} />}
  />,
  <Route
    key="artist-bio"
    path={ROUTE_CONSTANT.COMMON.ARTIST_BIO}
    element={<ProtectedRoute element={ArtistBio} />}
  />,
  <Route
    key="artist-bio"
    path={ROUTE_CONSTANT.COMMON.EDIT_ARTIST_BIO}
    element={<ProtectedRoute element={EditArtistBio} />}
  />,
  <Route
    key="edit-news"
    path={ROUTE_CONSTANT.COMMON.EDIT_NEWS}
    element={<ProtectedRoute element={EditNews} />}
  />,
  <Route
    key="edit-news"
    path={ROUTE_CONSTANT.COMMON.SUBSCRIBER}
    element={<ProtectedRoute element={NewsSubsriber} />}
  />,
  <Route
    key="youtube-videos"
    path={ROUTE_CONSTANT.COMMON.YOUTUBE_VIDEOS}
    element={<ProtectedRoute element={YoutubeVideo} />}
  />,
  <Route
    key="youtube-videos"
    path={ROUTE_CONSTANT.COMMON.BANNER_SECTION}
    element={<ProtectedRoute element={BannerSection} />}
  />,
];

export default commonRoutes;
