import React, { useEffect, useState } from "react";
import axios from "axios";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
const YoutubeVideo = () => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceQuery, setDebounceQuery] = useState(searchQuery);

  const fetchVideos = async () => {
    try {
      const response = await allAPiServicesCall.getYoutubeVideos(
        getAuthConfig()
      );
      setVideos(response.data);
    } catch (error) {
      setError("Failed to fetch videos.");
    }
  };

  const fetchSearchResults = async (query) => {
    try {
      const response = await allAPiServicesCall.getSearchYoutubeVideos(
        { query },
        getAuthConfig()
      );
      if (response && response.length > 0) {
        setVideos(response);
      } else {
        setVideos([]);
        console.log("error fetching videos");
      }
    } catch (error) {
      setError("Failed to fetch videos.");
      console.log("failed to fetch youtube videos");
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debounceQuery.trim()) {
      fetchSearchResults(debounceQuery);
    } else {
      setVideos([]);
      fetchVideos();
    }
  }, [debounceQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <CommonLayout>
      <div className="container mt-5 pt-2">
        <h2 className="text-center mt-3 mb-4">YouTube Videos</h2>
        <Form.Group controlId="searchAlbum">
          <Form.Control
            type="text"
            placeholder="Search for artists for adding to trending and recommended..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-album-input mb-4"
          />
        </Form.Group>
        {error && <p className="text-danger text-center">{error}</p>}
        <div className="row mb-5 pb-4">
          {videos.length > 0 ? (
            videos.map((video) => (
              <div className="col-md-4 mb-4" key={video._id}>
                <div className="card shadow-sm h-100">
                  <iframe
                    className="card-img-top"
                    width="100%"
                    height="200"
                    src={`https://www.youtube.com/embed/${video.videoId}`}
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="card-body">
                    <h5 className="card-title">{video.title}</h5>
                    {/* <p className="card-text text-truncate">{video.description}</p>
                                        <p className="text-muted">
                                            <strong>Channel:</strong> {video.channelTitle}
                                        </p> */}
                    <p className="text-muted">
                      <strong>Published:</strong>{" "}
                      {new Date(video.publishTime).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">No videos available.</p>
          )}
        </div>
      </div>
    </CommonLayout>
  );
};

export default YoutubeVideo;
