import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import CommonLayout from "../../../layouts/CommonLayout";

const Contactus = () => {
    const [contactUs, setContactUs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedContactUs, setSelectedContactUs] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const getContactUs = async () => {
        try {
            const response = await allAPiServicesCall.getContactus(
                {},
                getAuthConfig(),
                navigate
            );
            setContactUs(response.data);
        } catch (error) {
            console.log("error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleViewMore = (contactus) => {
        setSelectedContactUs(contactus);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        getContactUs();
    }, []);

    return (
        <CommonLayout>
            <div className=" container" style={{
                marginTop:"3%",
                marginBottom:"5%"
            }}>
                <div className="text-center mt-5">
                    <h3  style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "5%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }} >Contact Us List</h3>
                </div>

                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <div className="spinner"></div>
                    </div>
                ) : contactUs.length === 0 ? (
                    <div className="text-center my-5 text-muted">No Contact Us Found</div>
                ) : (
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style={{ width: "16%" }}>Name</th>
                                <th style={{ width: "20%" }}>Email</th>
                                <th style={{ width: "20%" }}>Subject</th>
                                <th style={{ width: "20%" }}>Message</th>
                                <th style={{ width: "10%" }}>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactUs.map((contact, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{contact.firstName}</td>
                                    <td>{contact.lastName}</td>
                                    <td>{contact.email}</td>
                                    <td>
                                        {contact.message.length > 5
                                            ? contact.message.substring(0, 25) + "..."
                                            : contact.message}
                                    </td>
                                    <td>{new Date(contact.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            style={{
                                                margin: "0 8px",
                                                padding: "6px 15px",
                                            }}
                                            onClick={() => handleViewMore(contact)}
                                        >
                                            View More
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}

                {/* Modal for displaying full details */}
                {selectedContactUs && (
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Contact Us Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <strong>First Name:</strong> {selectedContactUs.firstName}
                            </p>
                            <p>
                                <strong>Last Name:</strong> {selectedContactUs.lastName}
                            </p>
                            <p>
                                <strong>Email:</strong> {selectedContactUs.email}
                            </p>
                            <p>
                                <strong>Message:</strong> {selectedContactUs.message}
                            </p>
                            <p>
                                <strong>Date:</strong>{" "}
                                {new Date(selectedContactUs.createdAt).toLocaleDateString()}
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </CommonLayout>
    );
};

export default Contactus;
