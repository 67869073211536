import React, { useEffect, useRef, useState } from "react";
import CommonLayout from "../../../layouts/CommonLayout";
import { Container, Spinner } from "react-bootstrap";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { ROUTE_CONSTANT } from "../../../routes/constant";
import { useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';


const EditArtistBio = () => {
  const [artistBio, setArtistBio] = useState({
    artist_id: "",
    artist_description: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const editor = useRef(null)

  useEffect(() => {
    const fetchArtistBio = async () => {
      setLoading(true);
      try {
        let response = await allAPiServicesCall.getAllPlaylistBio(
          {},
          getAuthConfig()
        );

        if (response.success && response.status == 200) {
          const artistData = response.data.artistDetails[0];
          setArtistBio({
            artist_id: artistData.artist_id,
            artist_description: artistData.artist_description,
          });
        } else {
          console.warn("No data found or data is not an array");
        }
      } catch (error) {
        console.error("Error fetching artist bio:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArtistBio();
  }, []);

  const handleDescriptionChange = (content) => {
    setArtistBio((prevData) => ({
      ...prevData,
      artist_description: content,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await allAPiServicesCall.editArtistBio(
        artistBio,
        getAuthConfig(),
        navigate
      );
      if (response.success) {
        navigate(ROUTE_CONSTANT.COMMON.ARTIST_BIO);
      }
    } catch (error) {
      console.error(error);

    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonLayout>
      <Container>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div>
            <h3 className="mb-4" style={{
              textAlign: "center",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "2.8rem",
              fontWeight: "600",
              letterSpacing: "1.5px",
              textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
              marginBottom: "5%",
              background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
              WebkitBackgroundClip: "text",
            }}>Edit Artist Bio</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="descriptionEditor" className="form-label">
                  Description
                </label>
                <div>

                  <JoditEditor
                    ref={editor}
                    value={artistBio.artist_description} onChange={handleDescriptionChange}
                  />
                </div>
              </div>
            <div className="text-center ">
            <button type="submit" className="  btn btn-primary px-5 ">
                Save
              </button>
            </div>
            </form>
          </div>
        )}
      </Container>
    </CommonLayout>
  );
};

export default EditArtistBio;
